import React, {useCallback, useContext, useRef, useState} from "react";
import {connect} from "react-redux";
import {SafeAreaView} from "react-native-safe-area-context";
import StyleContext from "../../StyleContext";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import {ScrollView, TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import IconButton from "../../sharedComponents/iconButton/IconButton";
import ChevronIcon from "../../icons/ChevronIcon";
import GameSetupPlayers from "./GameSetupPlayers";
import Spacing from "../../styles/spacing";
import {
    backToPreviousStep, cancel,
    continueToNextStep, selectSetupTotalPlayers, setAddToCompletedCanastas, setAskToGoOut, setBlack3s,
    setCardsToDeal, setMaxWildCards, setNumberOfCanastas, setRed3s, setTotalDecks, setTotalPickupCards,
    shufflePlayers
} from "../../redux/viewer/gameSetupSlice";
import GameSetupTeams from "./GameSetupTeams";
import GameSetupStep from "./GameSetupStep";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {useNavigation} from "@react-navigation/native";
import Api from "../../services/Api";
import Analytics, {EVENTS} from "../../services/Analytics";

const GameSetupScreen = ({dispatch, userAccount, accessToken, gameSetup, totalPlayers}) => {

    const styleContext = useContext(StyleContext);
    const loadingContext = useContext(LoadingContext);
    const scrollViewRef = useRef(null);
    const navigation = useNavigation();
    const [errorMessage, setErrorMessage] = useState('');
    const [createGameLoading, setCreateGameLoading] = useState(false);
    const [invitedFriends, setInvitedFriends] = useState([]);

    const numberOfSteps = 12;
    const progress = gameSetup.currentStep / numberOfSteps * 100;
    const isReviewStep = gameSetup.currentStep === numberOfSteps;
    const isNormalContainer = gameSetup.currentStep <= 2;

    console.log(gameSetup);

    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false)
        return () => {
            setInvitedFriends([]);
        }
    }, []));

    useFocusEffect(useCallback(() => {
        zE('webWidget', 'hide');
    }, [navigation]));

    const handleOnBackPress = () => {
        if (gameSetup.currentStep === 1) {
            handleOnCancelPress();
        } else {
            dispatch(backToPreviousStep())
        }
    };

    const handleOnCancelPress = () => {
        dispatch(cancel())
        navigation.navigate('Home');
    };

    const handleCreateGameButton = async () => {
        setErrorMessage('');
        setCreateGameLoading(true);
        try {
            const game = await Api.createGame(accessToken, gameSetup.setup);
            setCreateGameLoading(false);
            dispatch(cancel());
            await Analytics.logEvent(EVENTS.GAME_CREATE, {
                id: game.id,
                alphanumeric_id: game.alphanumericId,
                total_players: gameSetup.setup.players.length
            });
            let params = {id: game.alphanumericId};
            const gameMessages = JSON.parse(game.gameMessages);
            const partialStates = JSON.parse(game.partialStates);

            if (partialStates.length > 0 || gameMessages.length > 0) {
                params.e = {ps: partialStates, gm: gameMessages};
            }

            navigation.navigate('GameTable', params);
        } catch(error) {
            setErrorMessage(error.message);
            setCreateGameLoading(false);
        }
    };

    const nextStep = () => {
        dispatch(continueToNextStep());
        if (scrollViewRef.current) {
            if (isReviewStep) {
                setTimeout(() => {
                    scrollViewRef.current.scrollTo({x: 0, y: 0, animated: true})
                }, 200);
            } else {
                setTimeout(() => {
                    scrollViewRef.current.scrollToEnd({animated: true})
                }, 200);
            }
        }
    }

    const handleOnShufflePlayers = (i) => {
        dispatch(shufflePlayers(i));
    }

    const handleOnOptionSelectedCards = (option) => {
        dispatch(setCardsToDeal(option));
        nextStep();
    }

    const handleOnOptionSelectedDecks = (option) => {
        dispatch(setTotalDecks(option));
        nextStep();
    }

    const handleOnOptionSelectedPickupCards = (option) => {
        dispatch(setTotalPickupCards(option));
        nextStep();
    }

    const handleOnOptionSelectedRed3s = (option) => {
        dispatch(setRed3s(option));
        nextStep();
    }

    const handleOnOptionSelectedMaxWildCards = (option) => {
        dispatch(setMaxWildCards(option));
        nextStep();
    }

    const handleOnOptionSelectedAddToCompletedCanastas = (option) => {
        dispatch(setAddToCompletedCanastas(option));
        nextStep();
    }

    const handleOnOptionSelectedNumberOfCanastas = (option) => {
        dispatch(setNumberOfCanastas(option));
        nextStep();
    }

    const handleOnOptionSelectedAskToGoOut = (option) => {
        dispatch(setAskToGoOut(option));
        nextStep();
    }

    const handleOnOptionSelectedBlack3s = (option) => {
        dispatch(setBlack3s(option));
        nextStep();
    }

    const handleSetInvitedFriends = (friends) => {
        setInvitedFriends(friends);
    };

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch',
                backgroundColor: Colors.background3,
                paddingTop: 72,
                overflow: 'hidden',
                position: 'absolute',
                maxHeight: '100%',
                height: '100%',
                width: '100%'
            }]}>

            <View style={{...ownStyles.header}}>
                <View style={{...ownStyles.headerProgressBar, width: progress + '%'}}/>
                <View style={{position: 'absolute', top: 15, left: 15, zIndex: 1, transform: [{rotate: '180deg'}]}}>
                    <IconButton icon={<ChevronIcon size={20} color={Colors.onBackground}/>}
                                onPress={handleOnBackPress} disabled={createGameLoading}/>
                </View>
                <TouchableOpacity style={{position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', right: 15, zIndex: 1}} onPress={handleOnCancelPress} disabled={createGameLoading}>
                    <StandardText>Cancel</StandardText>
                </TouchableOpacity>
                <View>
                    <StandardText style={{fontSize: 20, weight: 500, color: Colors.onBackground4}}>
                        {isReviewStep ? `Review Game Setup` : `Set up your game`}
                    </StandardText>
                </View>
            </View>

            {!isNormalContainer && (
                <View style={{height: '100%', paddingBottom: isReviewStep ? 98 : (!isNormalContainer ? 310 : 0)}}>
                    <ScrollView ref={scrollViewRef} style={{height: '100%'}}>
                        {gameSetup.currentStep > 2 && gameSetup.setup.players.length > 0 && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}># of players</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}></StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{totalPlayers}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 3 && gameSetup.setup.cardsToDeal !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Cards dealt</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select how many cards you want dealt in each player’s hand and in their foot. We recommend 11 or 13 cards in a 4 player game and 15 cards in a 2 player game.</StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.cardsToDeal}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 4 && gameSetup.setup.numberOfCanastas !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Number of canastas to go out`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>A "natural" canasta means a meld of 7+ cards with no wild cards and an "unnatural" canasta means a meld of 7+ cards that includes one or more wild cards.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.numberOfCanastas === 1 ? `1 Natural, 1 Unnatural` : [2, 3].includes(totalPlayers) ? '3 Natural, 4 Unnatural' : '4 Natural, 5 Unnatural'}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 5 && gameSetup.setup.totalDecks !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    {/* Note: This logic is replicated in the backend so any changes to it needs to be updated there as well. */}
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Decks used</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>
                                        We recommend {gameSetup.setup.numberOfCanastas == 1 ? ([2, 3].includes(totalPlayers) ? 3 : totalPlayers + 1) : ([2, 3].includes(totalPlayers) ? 5 : totalPlayers + 1)} decks of cards for games with {gameSetup.setup.numberOfCanastas == 1 ? '1' : ([2, 3].includes(totalPlayers) ? '3' : '4')} natural canasta(s) needed, {gameSetup.setup.numberOfCanastas == 1 ? '1' : ([2, 3].includes(totalPlayers) ? '4' : '5')} unnatural canasta(s) needed and {totalPlayers} players.
                                    </StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.numberOfCanastas == 1 ? ([2, 3].includes(totalPlayers) ? 3 : totalPlayers + 1) : ([2, 3].includes(totalPlayers) ? 5 : totalPlayers + 1)}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 6 && gameSetup.setup.totalPickupCards !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>By picking up a card you'll
                                        get</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select how many cards you get when you pick up cards from the “Pick Up” pile. We recommend 6.</StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.totalPickupCards}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 7 && gameSetup.setup.red3s !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Red 3s</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>"Play immediately" will automatically play your red 3, give you 100 points and let you draw another card. "Use as discard" lets you use any red 3s in your hand as a valid discard.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.red3s === 1 ? `Use as discard` : `Play immediately`}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 8 && gameSetup.setup.maxWildCards !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Max number of wild cards in melds</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select the maximum number of wild cards you can use in an unnatural meld. We recommend 3.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.maxWildCards}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 9 && gameSetup.setup.addToCompletedCanastas !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Cards can be added to\ncompleted canastas`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>"Any time" lets you add additional cards to a canasta at any time during a round, while "Only at end" only lets you play additional cards when going out.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.addToCompletedCanastas === 1 ? `Any time` : `Only at end`}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 10 && gameSetup.setup.askToGoOut !== null && [4, 6].includes(totalPlayers) && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Should players ask partner to go out?`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select "Yes" to require a player to ask their partner for permission before they go out.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.askToGoOut === 1 ? `Yes` : `No`}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > 11 && gameSetup.setup.black3s !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Can Black 3s be melded when going out?`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select "Yes" to allow a player to meld three or more Black 3s from their hand when they are trying to go out.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.black3s === 1 ? `Yes` : `No`}</StandardText>
                            </View>
                        )}

                    </ScrollView>
                </View>
            )}

            <View
                style={{...(gameSetup.currentStep <= 2 ? ownStyles.contentContainer : (isReviewStep ? ownStyles.contentContainerReview : ownStyles.contentContainerModal))}}>

                {gameSetup.currentStep === 1 && (
                    <GameSetupPlayers onContinuePress={() => {
                        dispatch(continueToNextStep())
                    }} invitedFriends={invitedFriends} setInvitedFriends={handleSetInvitedFriends}/>
                )}

                {gameSetup.currentStep === 2 && (
                    <GameSetupTeams onContinuePress={() => {
                        dispatch(continueToNextStep())
                    }} onShufflePlayers={handleOnShufflePlayers} gameSetup={gameSetup} userAccount={userAccount}/>
                )}

                {gameSetup.currentStep === 3 && (
                    <GameSetupStep
                        stepLabel={'How many cards do you want to be dealt?'}
                        stepDescription={'Select how many cards you want dealt in each player’s hand and in their foot. We recommend 11 or 13 cards in a 4 player game and 15 cards in a 2 player game.'}
                        stepOptions={[{id: 11, label: '11'}, {id: 13, label: '13'}, {id: 15, label: '15'}]}
                        selectedOptionId={gameSetup.setup.cardsToDeal}
                        onOptionSelected={handleOnOptionSelectedCards}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 4 && (
                    <GameSetupStep
                        stepLabel={'Number of canastas to go out'}
                        stepDescription={'A "natural" canasta means a meld of 7+ cards with no wild cards and an "unnatural" canasta means a meld of 7+ cards that includes one or more wild cards.'}
                        stepOptions={[{id: 1, label: `1 Natural, 1 Unnatural`, extra: `Best for shorter, more\nlively games`}, {id: 2, label: ([2, 3].includes(totalPlayers) ? '3 Natural, 4 Unnatural' : '4 Natural, 5 Unnatural'), extra: `Best for longer, more\nstrategic games` }]}
                        selectedOptionId={gameSetup.setup.numberOfCanastas}
                        onOptionSelected={handleOnOptionSelectedNumberOfCanastas}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                        vertical={true}
                    />
                )}

                {gameSetup.currentStep === 5 && (
                    <GameSetupStep
                        stepLabel={'How many decks do you want to be used?'}
                        stepDescription={'We recommend 5 decks of cards for games with 2 or 4 players.'}
                        stepOptions={[{id: 5, label: '5'}, {id: 6, label: '6'}]}
                        selectedOptionId={gameSetup.setup.totalDecks}
                        onOptionSelected={handleOnOptionSelectedDecks}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 6 && (
                    <GameSetupStep
                        stepLabel={'How many cards do you get if you pick up from the discard pile'}
                        stepDescription={'Select how many cards you get when you pick up cards from the “Pick Up” pile. We recommend 6.'}
                        stepOptions={[{id: 6, label: '6'}, {id: 7, label: '7'}]}
                        selectedOptionId={gameSetup.setup.totalPickupCards}
                        onOptionSelected={handleOnOptionSelectedPickupCards}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 7 && (
                    <GameSetupStep
                        stepLabel={'How do you want Red 3s to be used?'}
                        stepDescription={'"Play immediately" will automatically play your red 3, give you 100 points and let you draw another card. "Use as discard" lets you use any red 3s in your hand as a valid discard.'}
                        stepOptions={[{id: 1, label: 'Use as discard'}, {id: 2, label: 'Play immediately'}]}
                        selectedOptionId={gameSetup.setup.red3s}
                        onOptionSelected={handleOnOptionSelectedRed3s}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 8 && (
                    <GameSetupStep
                        stepLabel={'Max number of wild cards in melds'}
                        stepDescription={'Select the maximum number of wild cards you can use in an unnatural meld. We recommend 3.'}
                        stepOptions={[{id: 2, label: '2'}, {id: 3, label: '3'}]}
                        selectedOptionId={gameSetup.setup.maxWildCards}
                        onOptionSelected={handleOnOptionSelectedMaxWildCards}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 9 && (
                    <GameSetupStep
                        stepLabel={'Cards can be added to completed canastas'}
                        stepDescription={'"Any time" lets you add additional cards to a canasta at any time during a round, while "Only at end" only lets you play additional cards when going out.'}
                        stepOptions={[{id: 1, label: 'Any time'}, {id: 2, label: 'Only at end'}]}
                        selectedOptionId={gameSetup.setup.addToCompletedCanastas}
                        onOptionSelected={handleOnOptionSelectedAddToCompletedCanastas}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 10 && (
                    <GameSetupStep
                        stepLabel={'Should players ask partner to go out?'}
                        stepDescription={'Select "Yes" to require a player to ask their partner for permission before they go out.'}
                        stepOptions={[{id: 1, label: 'Yes'}, {id: 2, label: 'No'}]}
                        selectedOptionId={gameSetup.setup.askToGoOut}
                        onOptionSelected={handleOnOptionSelectedAskToGoOut}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === 11 && (
                    <GameSetupStep
                        stepLabel={'Can Black 3s be melded when going out?'}
                        stepDescription={'Select "Yes" to allow a player to meld three or more Black 3s from their hand when they are trying to go out.'}
                        stepOptions={[{id: 1, label: 'Yes'}, {id: 2, label: 'No'}]}
                        selectedOptionId={gameSetup.setup.black3s}
                        onOptionSelected={handleOnOptionSelectedBlack3s}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {isReviewStep && (
                    <View style={{width: '100%', maxWidth: 420}}>
                        <LoadingButton
                            label={'Start game'}
                            backgroundColor={Colors.primary6}
                            padding={Spacing.large}
                            loading={createGameLoading}
                            onPress={handleCreateGameButton} />
                    </View>
                )}

            </View>

        </SafeAreaView>
    );
};

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        accessToken: state.auth.login.accessToken,
        friends: state.viewer.friends.data,
        friendsCursor: state.viewer.friends.cursor,
        friendsLoading: state.viewer.friends.loading,
        gameSetup: state.viewer.gameSetup,
        totalPlayers: selectSetupTotalPlayers(state.viewer.gameSetup)
    }
};

export default connect(mapStateToProps)(GameSetupScreen);

const ownStyles = {
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        height: 72,
        backgroundColor: Colors.background,
        zIndex: 1
    },
    headerProgressBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: Colors.primary5
    },
    contentContainer: {
        flex: 1,
        width: '100%',
        padding: Spacing.base,
        paddingBottom: 50,
        backgroundColor: 'transparent',
        alignItems: 'center',
        overflowY: 'auto'
    },
    contentContainerModal: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderWidth: 1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderColor: 'rgba(192, 192, 192, 1)',
        flex: 1,
        width: '100%',
        height: 300,
        backgroundColor: Colors.background,
        padding: Spacing.base,
        alignItems: 'center'
    },
    contentContainerReview: {
        position: 'fixed',
        bottom: 0,
        borderTopWidth: 1,
        borderTopColor: 'rgba(192, 192, 192, 1)',
        flex: 1,
        width: '100%',
        height: 88,
        backgroundColor: Colors.background,
        padding: Spacing.base,
        alignItems: 'center'
    },
    gameSetupRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: Spacing.large,
        paddingStart: Spacing.base,
        borderBottomWidth: 1,
        borderBottomColor: Colors.onBackgroundBorder2
    },
    gameSetupLabelContainer: {
        flex: 1
    },
    gameSetupLabel: {
        color: Colors.onBackground3
    },
    gameSetupLabelDescription: {
        fontSize: 14,
        color: Colors.onBackground3,
        opacity: 0.7,
        marginTop: Spacing.small,
    },
    gameSetupValue: {
        color: Colors.primary4,
        paddingStart: Spacing.base,
        textAlign: 'right'
    }
};
