import { useFocusEffect, useNavigation } from '@react-navigation/core';
import React, { useCallback, useContext, useState } from 'react';
import { ActivityIndicator, Image, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { connect } from "react-redux";
import Colors from "../../styles/colors";
import { LoadingContext } from '../../context/LoadingContext';
import SubscriptionBlocked from '../../game/components/SubscriptionBlocked';
import Api from '../../services/Api';
import StyleContext from '../../StyleContext';
import StandardText from '../../sharedComponents/standardText/StandardText';
import blurredSuitsBg from './../../../assets/blurred_suits_bg.png';
import LoadingButton from '../../sharedComponents/loadingButton/LoadingButton';
import DailyTournamentLeaderboards from '../../game/components/DailyTournamentLeaderboards';
import { format, toDate, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { add, isEqual, parseISO, sub } from 'date-fns';
import IconButton from '../../sharedComponents/iconButton/IconButton';
import ChevronIcon from '../../icons/ChevronIcon';
import TabGroup from '../../sharedComponents/tabGroup/TabGroup';
import WebView from "react-native-web-webview/src";
import Timer from '../../sharedComponents/timer/Timer';

const DailyTournamentScreen = ({ dispatch, accessToken, userAccount, subscription, route }) => {

    const navigation = useNavigation();
    const [isUserAccountBlocked, setIsUserAccountBlocked] = useState(null);
    const [generalAppInfo, setGeneralAppInfo] = useState(null);
    const [selectedTab, setSelectedTab] = useState(1);
    const [userAccountDuplicateDailyTournament, setUserAccountDuplicateDailyTournament] = useState(null);
    const [createGameSoloLoading, setCreateGameSoloLoading] = useState(false);
    const [createGameTeamLoading, setCreateGameTeamLoading] = useState(false);
    const [solo, setSolo] = useState(true);
    const [mode, setMode] = useState(1);
    const [currentTournamentDate, setCurrentTournamentDate] = useState(null);
    const [firstDateWithData, setFirstDateWithData] = useState(null);
    const [date, setDate] = useState(null);
    const [currentTournamentEndDate, setCurrentTournamentEndDate] = useState(null);
    const [leaderboardRows, setLeaderboardRows] = useState([]);
    const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);

    useFocusEffect(useCallback(() => {
        zE('webWidget', 'show');
    }, [navigation]));


    useFocusEffect(useCallback(() => {
        return () => {
            setIsUserAccountBlocked(null);
            setDate(null);
            navigation.setParams({ from_game: undefined, solo: undefined, date: undefined })
        }
    }, []));

    useFocusEffect(
        useCallback(() => {
            if (route.params && route.params.from_game) {
                setSelectedTab(2);
                setMode(1)
            }

            if (route.params && route.params.solo === '0') {
                setSolo(false);
            }

            if (route.params && route.params.date) {
                setDate(utcToZonedTime(toDate(route.params.date), 'UTC'))
            }

        }, [route.params])
    );

    useFocusEffect(useCallback(() => {
        if (userAccountDuplicateDailyTournament && generalAppInfo) {
            loadingContext.setScreenLoading(false);

            const endDate = parseISO(userAccountDuplicateDailyTournament.currentDuplicateConfigGame.endDate);
            setCurrentTournamentEndDate(endDate)
        }
    }, [userAccountDuplicateDailyTournament, generalAppInfo]));

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getInfo(accessToken);
        }
    }, [accessToken]));


    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getDailyTournament(accessToken);
        }
    }, [accessToken]));

    useFocusEffect(useCallback(() => {
        if (subscription?.subscriptionDetails?.isUserAccountBlocked === true) {
            setIsUserAccountBlocked(true);
        } else if (subscription?.subscriptionDetails?.isUserAccountBlocked === false) {
            setIsUserAccountBlocked(false);
        }
    }));

    useFocusEffect(useCallback(() => {
        if (currentTournamentDate && date === null) {
            setDate(currentTournamentDate);            
        }
    }, [currentTournamentDate, date]));

    useFocusEffect(useCallback(() => {
        if (accessToken && date !== null) {
            getLeaderboard();
        }
    }, [accessToken, solo, mode, date]));

    const getInfo = async (accessToken) => {
        const generalAppInfoData = await Api.generalAppInfo(accessToken);
        setGeneralAppInfo(generalAppInfoData);
    };

    const refreshScreen = async () => {
        location.reload();
    }

    const getDailyTournament = async (accessToken) => {
        const dailyTournament = await Api.viewerUserAccountDailyTournament(accessToken);
        setUserAccountDuplicateDailyTournament(dailyTournament);
        const currentTournamentDate = utcToZonedTime(toDate(dailyTournament.currentDuplicateConfigGame.date), 'UTC');
        setFirstDateWithData(utcToZonedTime(toDate("2021-10-01T00:00:00.000Z"), 'UTC'));
        setCurrentTournamentDate(currentTournamentDate)

        if (dailyTournament.hasEverPlayedTournament) {
            setSelectedTab(2);
        }
    };

    const getLeaderboard = async () => {
        setLoadingLeaderboard(true);
        const leaderboard = await Api.viewerDailyTournamentLeaderboard(accessToken, solo, mode, format(date, "yyyy-MM-dd"));
        setLeaderboardRows(leaderboard);
        setLoadingLeaderboard(false);
    };

    const handleOnDateChanged = (next) => {
        if (next) {
            setDate(add(date, mode === 1 ? { days: 1 } : { months: 1 }))
        } else {
            setDate(sub(date, mode === 1 ? { days: 1 } : { months: 1 }))
        }
    }

    const handleCreateGamePress = async (solo) => {

        const alphanumericId = solo ? userAccountDuplicateDailyTournament?.soloGame?.alphanumericId : userAccountDuplicateDailyTournament?.teamGame?.alphanumericId
        if (alphanumericId) {
            navigation.navigate('GameTable', { id: alphanumericId });
            return;
        }

        if (solo) {
            setCreateGameSoloLoading(true);
        } else {
            setCreateGameTeamLoading(true);
        }

        const game = await Api.createDuplicateTournamentGame(accessToken, solo);

        let params = { id: game.alphanumericId };
        const gameMessages = JSON.parse(game.gameMessages);
        const partialStates = JSON.parse(game.partialStates);

        if (partialStates.length > 0 || gameMessages.length > 0) {
            params.e = { ps: partialStates, gm: gameMessages };
        }

        setCreateGameSoloLoading(false);
        setCreateGameTeamLoading(false);
        navigation.navigate('GameTable', params);
    }

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch'
            }]}>

            {isUserAccountBlocked &&
                <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1 }}>
                    <SubscriptionBlocked navigation={navigation} contactUsUrl={generalAppInfo?.submitSupportRequestUrl} />
                </View>
            }

            <View style={{ ...ownStyles.blurredSuitsBgContainer }}>
                <Image source={{ uri: blurredSuitsBg }} style={{ width: 300, height: 306 }} />
            </View>

            <View style={{ ...ownStyles.topBar, }}>
                <View style={{
                    position: 'absolute',
                    top: 15,
                    left: 15,
                    zIndex: 1,
                    transform: [{ rotate: '180deg' }]
                }}>
                    <IconButton icon={<ChevronIcon size={20} color={Colors.onBackground} />}
                        onPress={() => { navigation.navigate('Home') }} />
                </View>
                <View style={{ marginStart: 50 }}>
                    <StandardText style={{ ...ownStyles.screenTitle }}>Daily Tournament</StandardText>
                </View>
            </View>

            <View style={{ width: '100%', flexGrow: 0 }}>
                <View style={{ ...ownStyles.startGameButtonsContainer }}>
                    <View style={{ width: 300 }}>
                        <LoadingButton label={userAccountDuplicateDailyTournament?.completedSoloGame ? "SOLO Game Completed for Today" : (userAccountDuplicateDailyTournament?.startedSoloGame ? "Continue SOLO Game" : "Start SOLO Game")} loading={createGameSoloLoading} showChevron={false} backgroundColor={Colors.tournamentColor} maxWidth={'100%'} onPress={() => handleCreateGamePress(true)} disabled={!userAccountDuplicateDailyTournament || createGameTeamLoading || userAccountDuplicateDailyTournament?.completedSoloGame} />
                        <LoadingButton label={userAccountDuplicateDailyTournament?.completedTeamGame ? "TEAM Game Completed for Today" : (userAccountDuplicateDailyTournament?.startedTeamGame ? "Continue TEAM Game" : "Start TEAM Game")} loading={createGameTeamLoading} showChevron={false} backgroundColor={Colors.tournamentColor} maxWidth={'100%'} onPress={() => handleCreateGamePress(false)} disabled={!userAccountDuplicateDailyTournament || createGameSoloLoading || userAccountDuplicateDailyTournament?.completedTeamGame} />
                    </View>
                </View>

                {currentTournamentEndDate && (
                    <View style={{ alignItems: 'center', marginBottom: 20 }}>
                        <Timer duration={Math.floor(currentTournamentEndDate.getTime() - Date.now()) / 1000} withHours={true} fontSize={16} onExpire={() => { refreshScreen() }} />
                        <View>
                            <StandardText style={{ weight: 500 }}>left to complete the {userAccountDuplicateDailyTournament?.currentDuplicateConfigGame.displayNameGeneric} game</StandardText>
                        </View>
                    </View>
                )}

            </View>

            <View style={{ flex: 1, alignItems: 'center' }}>
                <View style={{ flex: 1, width: '100%', maxWidth: 500 }}>

                    <TabGroup selectedTabId={selectedTab} tabs={[{ id: 1, label: 'Rules' }, { id: 2, label: 'Leaderboards' }]} onTabSelected={(id) => { setSelectedTab(id) }} />

                    {selectedTab === 1 && generalAppInfo && (
                        <WebView
                            source={{ html: '<html><head><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap" rel="stylesheet"><style>body {font-family: \'Roboto\', sans-serif}</style></head><body>' + generalAppInfo.duplicateRules + '</body></html>' }} />
                    )}

                    {selectedTab === 2 && date && (
                        <DailyTournamentLeaderboards
                            loading={loadingLeaderboard}
                            solo={solo}
                            mode={mode}
                            date={date}
                            rows={leaderboardRows}
                            onSoloChanged={s => setSolo(s)}
                            onModeChanged={m => { setMode(m); setDate(currentTournamentDate) }}
                            onDateChanged={handleOnDateChanged}
                            showNextDate={!isEqual(date, currentTournamentDate)}
                            showPreviousDate={(mode === 1 && !isEqual(date, firstDateWithData)) || (mode === 2 && format(date, "yyyy-MM") !== format(firstDateWithData, "yyyy-MM"))} />

                    )}

                    <View style={{ marginTop: 20 }}>
                        {loadingLeaderboard ? (
                            <ActivityIndicator animating={true} color={Colors.tournamentColor} />
                        ) : (
                            <View>
                            </View>
                        )}
                    </View>
                </View>
            </View>

        </SafeAreaView>
    );
};

const ownStyles = {
    topBar: {
        width: '100%',
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 20,
        weight: 500,
        color: Colors.onBackground4
    },
    blurredSuitsBgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    startGameButtonsContainer: {
        width: '100%',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    statsContainer: {

    },
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.login.accessToken,
        userAccount: state.auth.login.userAccount,
        gamesError: state.viewer.games.error,
        subscription: state.auth.profile.subscription,
    }
};

export default connect(mapStateToProps)(DailyTournamentScreen);