import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import CheckIcon from '../../icons/CheckIcon';
import PlusIcon from '../../icons/PlusIcon';
import StandardText from '../../sharedComponents/standardText/StandardText';
import Colors from '../../styles/colors';

const Meld = ({
                  dimmed,
                  mobileDisplay,
                  ipadPortraitDisplay,
                  viewerTeamMeld,
                  opponentTeamMeld,
                  cardRank,
                  showRankLabel,
                  onMeldPressed = () => {},
                  disabled
              }) => {

    const viewerTeamHasMeld = viewerTeamMeld?.meldCards.length > 0;
    const opponentTeamHasMeld = opponentTeamMeld?.meldCards.length > 0;
    const progressBarOffset = viewerTeamHasMeld && viewerTeamMeld.meldCards.length <= 2 ? (100 / 3) * (3 - viewerTeamMeld.meldCards.length) : 0;
    const wildDotsVisibleViewer = viewerTeamHasMeld && (viewerTeamMeld.meldCards.filter((meldCard) => meldCard.card.isWild).length > 0 || viewerTeamMeld.numberOfWildCardsAllowed > 0);
    const wildDotsVisibleOpponent = opponentTeamHasMeld && (opponentTeamMeld.meldCards.filter((meldCard) => meldCard.card.isWild).length > 0);
    const meldheight = ipadPortraitDisplay || !mobileDisplay ? 44 : 38;
    const meldCountWidth = 40;

    const renderCheckIcon = (opponent = false, wildDotsVisible = false) => {
        return (
            <View style={{width: opponent ? 38 : meldCountWidth, alignItems: 'center'}}>
                <View style={{...ownStyles.checkIconContainer}}>
                    <View style={{left: 3, top: 3}}>
                        <CheckIcon size={15} color={Colors.success}/>
                    </View>
                </View>
            </View>
        );
    }

    const getLabelFromRank = (rank) => {
        switch (rank) {
            case '4':
                return 'FOURS';
            case '5':
                return 'FIVES';
            case '6':
                return 'SIXES';
            case '7':
                return 'SEVENS';
            case '8':
                return 'EIGHTS';
            case '9':
                return 'NINES';
            case '10':
                return 'TENS';
            case 'J':
                return 'JACKS';
            case 'Q':
                return 'QUEENS';
            case 'K':
                return 'KINGS';
            case 'A':
                return 'ACES';
            default:
                return '';
        }
    }

    const hasDots = viewerTeamHasMeld && (viewerTeamMeld.meldCards.filter((meldCard) => meldCard.card.isWild).length > 0 || viewerTeamMeld.numberOfWildCardsAllowed > 0);

    return (
        <View style={[{...ownStyles.meldRow}, dimmed && {opacity: 0.5}]}>

            <TouchableOpacity onPress={() => onMeldPressed(cardRank, viewerTeamMeld)} style={{
                ...ownStyles.meldButton, ...(viewerTeamHasMeld && viewerTeamMeld.meldCards.length > 2 ? (viewerTeamMeld?.isNatural ? ownStyles.naturalMeldButton : ownStyles.unnaturalMeldButton) : {}),
                height: meldheight
            }} disabled={disabled}>

                <View style={{
                    ...ownStyles.meldButtonBackground, ...(viewerTeamHasMeld && viewerTeamMeld.meldCards.length <= 2 ? {
                        backgroundColor: Colors.redCard,
                        right: progressBarOffset + '%'
                    } : {})
                }} />

                {showRankLabel &&
                <View style={{...ownStyles.rankLabelContainer}}>
                    <StandardText
                        style={{...ownStyles.rankLabelText, ...(viewerTeamHasMeld && viewerTeamMeld.meldCards.length >= 3 ? {color: Colors.onPrimary} : {})}}>{getLabelFromRank(cardRank)}</StandardText>
                </View>
                }

                <View style={{width: meldCountWidth}}/>

                {!dimmed && <View style={{...ownStyles.plusIcon}}>
                    <StandardText style={{...ownStyles.plusIconText}}>
                        <PlusIcon
                            color={viewerTeamHasMeld && viewerTeamMeld.meldCards.length > 2 ? Colors.onPrimary : Colors.primary}
                            size={16}/>
                    </StandardText>
                </View>}

                {viewerTeamHasMeld && viewerTeamMeld.meldCards.length >= 7 ? (
                    renderCheckIcon(false, wildDotsVisibleViewer)
                ) : (
                    <View style={{width: meldCountWidth, textAlign: 'center'}}>
                        <StandardText
                            style={{...ownStyles.viewerMeldCountText, ...(viewerTeamHasMeld && viewerTeamMeld.meldCards.length > 2 ? {color: Colors.onPrimary} : {}), ...(!hasDots ? {marginTop: 0} : {})}}
                            weight={500}>
                            {viewerTeamHasMeld && <StandardText
                                style={{...ownStyles.xNumberOfCards, ...(viewerTeamHasMeld && viewerTeamMeld.meldCards.length > 2 ? {color: Colors.onPrimary} : {})}}>x</StandardText>}
                            {viewerTeamHasMeld ? viewerTeamMeld.meldCards.length : 0}
                        </StandardText>
                        <View style={{...ownStyles.wildDotsContainer, width: meldCountWidth}}>
                            {viewerTeamHasMeld && !viewerTeamMeld.isNatural && viewerTeamMeld.meldCards.filter((meldCard) => meldCard.card.isWild).map((meldCard, i) => {
                                return (
                                    <View key={i}
                                          style={{...ownStyles.wildDot, ...(viewerTeamMeld.meldCards.length < 3 ? {backgroundColor: Colors.onBackground} : {})}}/>
                                );
                            })}

                            {viewerTeamHasMeld && [...Array(viewerTeamMeld.numberOfWildCardsAllowed)].map((e, i) => {
                                return <View key={i}
                                             style={{...ownStyles.wildDotEmpty, ...(viewerTeamMeld.meldCards.length < 3 ? {borderColor: Colors.onBackground} : {})}}/>
                            })}
                        </View>

                    </View>
                )}

            </TouchableOpacity>

            <View style={{...ownStyles.rankIndicator, height: meldheight, maxHeight: 35}}>
                <StandardText style={{...ownStyles.rankText}}>{cardRank}</StandardText>
            </View>

            <View style={{
                ...ownStyles.opponentMeld, ...(opponentTeamHasMeld ? (opponentTeamMeld?.isNatural ? ownStyles.naturalOpponentMeld : ownStyles.unnaturalOpponentMeld) : {}), ...(opponentTeamHasMeld && opponentTeamMeld.meldCards.length >= 7 ? {background: opponentTeamMeld?.isNatural ? Colors.redCard : Colors.blackCard} : {}),
                height: meldheight
            }}>
                {opponentTeamHasMeld && opponentTeamMeld.meldCards.length >= 7 ? (
                    renderCheckIcon(true, wildDotsVisibleOpponent)
                ) : (
                    <StandardText style={{
                        width: '100%',
                        textAlign: 'center',
                        fontSize: 18,
                        color: opponentTeamHasMeld && opponentTeamMeld?.isNatural ? Colors.redCard : Colors.onBackground
                    }} weight={500}>
                        {opponentTeamHasMeld && <StandardText style={{
                            ...ownStyles.xNumberOfCards,
                            color: opponentTeamHasMeld && opponentTeamMeld?.isNatural ? Colors.redCard : Colors.onBackground
                        }}>x</StandardText>}
                        {opponentTeamHasMeld ? opponentTeamMeld.meldCards.length : 0}
                    </StandardText>
                )}

                <View style={{...ownStyles.wildDotsContainer, width: '100%'}}>
                    {opponentTeamHasMeld && !opponentTeamMeld.isNatural && opponentTeamMeld.meldCards.filter((meldCard) => meldCard.card.isWild).map((meldCard, i) => {
                        return (
                            <View key={i}
                                  style={{...ownStyles.wildDot, ...(opponentTeamMeld.meldCards.length >= 7 ? {} : {backgroundColor: Colors.blackCard})}}/>
                        );
                    })}
                </View>

            </View>

        </View>
    );
};

const ownStyles = {
    meldRow: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        paddingLeft: 15,
        paddingRight: 15,
    },
    rankIndicator: {
        justifyContent: 'center',
        borderWidth: 0,
        backgroundColor: Colors.primary,
        borderRadius: 3,
        width: 27,
        height: 35,
        flexGrow: 0,
        marginRight: 15
    },
    rankText: {
        position: 'absolute',
        fontSize: 18,
        width: 26,
        textAlign: 'center',
        top: 6,
        left: 0,
        color: Colors.onPrimary,
        opacity: 1,
        weight: 500
    },
    meldButton: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        height: 44,
        width: 100,
        backgroundColor: Colors.background,
        borderRadius: 2,
        marginRight: 15,
        justifyContent: 'space-between'
    },
    meldButtonBackground: {
        backgroundColor: 'transparent',
        opacity: 0.3,
        position: 'absolute',
        top: 0,
        right: '66%',
        bottom: 0,
        left: 0,
        zIndex: -1
    },
    naturalMeldButton: {
        backgroundColor: Colors.redCard,
        boxShadow: '0px 13.114px 17.4853px rgba(255, 74, 107, 0.25)'
    },
    unnaturalMeldButton: {
        backgroundColor: Colors.blackCard,
        boxShadow: '0px 13.114px 17.4853px rgba(1, 11, 38, 0.25)'
    },
    opponentMeld: {
        width: 40,
        height: 44,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: 'transparent',
        background: 'radial-gradient(93.75% 93.75% at 50% 50%, rgba(217, 217, 217, 0) 0%, #D9D9D9 100%)',
        justifyContent: 'center'
    },
    naturalOpponentMeld: {
        borderColor: Colors.redCard
    },
    unnaturalOpponentMeld: {
        borderColor: Colors.blackCard
    },
    viewerMeldCountText: {
        // position: 'absolute',
        // right: 20,
        marginTop: -2,
        fontSize: 18,
        color: Colors.onBackground,
    },
    wildDotsContainer: {
        // position: 'absolute',
        // right: 0,
        // bottom: 5,
        width: 37,
        flexDirection: 'row',
        justifyContent: 'center',
        // paddingTop: 2
    },
    wildDot: {
        height: 8,
        width: 8,
        marginLeft: 2,
        marginRight: 2,
        marginTop: 2,
        backgroundColor: Colors.background,
        borderRadius: 10
    },
    wildDotEmpty: {
        height: 8,
        width: 8,
        marginLeft: 2,
        marginRight: 2,
        marginTop: 2,
        backgroundColor: 'transparent',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.background,
    },
    checkIconContainer: {
        fontSize: 20,
        backgroundColor: Colors.background,
        borderRadius: 100,
        width: 21,
        height: 21
    },
    plusIcon: {
        marginTop: 1
    },
    plusIconText: {
        fontSize: 20,
        color: Colors.onPrimary
    },
    xNumberOfCards: {
        fontSize: 13,
    },
    rankLabelContainer: {
        position: 'absolute',
        left: 10,
        top: 12
    },
    rankLabelText: {
        weight: 700,
        fontStyle: 'italic',
        fontSize: 18,
        color: Colors.primary
    }
};

export default React.memo(Meld);
