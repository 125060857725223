import React from 'react';
import { Svg, Path } from 'react-native-svg';

const BlockIcon = ({ size, color }) => {
    return (
        <svg width="178" height="126" viewBox="0 0 178 126" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d)">
                <rect x="32.7539" y="106.115" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-106.497 32.7539 106.115)" fill="white" stroke="#848484" strokeWidth="1.20485"/>
                <ellipse cx="24.4722" cy="40.7337" rx="4.8298" ry="4.8298" transform="rotate(-16.4966 24.4722 40.7337)" fill="#848484"/>
                <ellipse cx="33.7339" cy="37.991" rx="4.8298" ry="4.8298" transform="rotate(-16.4966 33.7339 37.991)" fill="#848484"/>
                <path d="M32.2931 51.1124L21.9134 44.8494L28.9509 38.8475L38.1218 40.0493L32.8264 50.9545C32.7296 51.1537 32.4827 51.2268 32.2931 51.1124Z" fill="#848484"/>
                <path d="M55.2069 68.2732L56.4863 67.8944L58.0214 73.0778C58.2308 73.7852 58.1755 74.4126 57.8555 74.9603C57.5388 75.5069 57.0183 75.8874 56.294 76.1019C55.5223 76.3304 54.869 76.3122 54.334 76.0473C53.7991 75.7824 53.4289 75.303 53.2234 74.6091L54.5027 74.2303C54.619 74.6229 54.8032 74.8905 55.0552 75.033C55.3097 75.1712 55.6213 75.1856 55.9903 75.0764C56.3355 74.9741 56.5761 74.7796 56.712 74.4927C56.8503 74.2014 56.8598 73.8544 56.7405 73.4516L55.2069 68.2732Z" fill="#848484"/>
                <rect x="45.9355" y="90.9688" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-87.0513 45.9355 90.9688)" fill="white" stroke="#848484" strokeWidth="1.20485"/>
                <path d="M64.3838 33.2402L64.0318 40.0734" stroke="#848484" strokeWidth="1.00404"/>
                <ellipse cx="69.2073" cy="33.4882" rx="4.8298" ry="4.8298" transform="rotate(-177.051 69.2073 33.4882)" fill="#848484"/>
                <rect x="62.4609" y="28.8423" width="4.29315" height="4.29315" transform="rotate(2.94867 62.4609 28.8423)" fill="#848484"/>
                <ellipse cx="59.5608" cy="32.9916" rx="4.8298" ry="4.8298" transform="rotate(-177.051 59.5608 32.9916)" fill="#848484"/>
                <path d="M65.3748 21.2044L72.9902 30.4595L56.1083 29.5899L64.6343 21.1663C64.8444 20.9588 65.1872 20.9764 65.3748 21.2044Z" fill="#848484"/>
                <path d="M79.7049 62.7605L81.0374 62.8291L80.7593 68.2279C80.7214 68.9646 80.4603 69.5379 79.9762 69.9477C79.4956 70.3577 78.8781 70.5433 78.1237 70.5044C77.32 70.463 76.71 70.2284 76.2938 69.8005C75.8775 69.3726 75.688 68.7973 75.7252 68.0746L77.0578 68.1433C77.0367 68.5522 77.1213 68.8658 77.3115 69.0841C77.5054 69.2991 77.7945 69.4165 78.1787 69.4363C78.5383 69.4548 78.8299 69.3514 79.0536 69.1262C79.281 68.8975 79.4055 68.5735 79.4271 68.154L79.7049 62.7605Z" fill="#848484"/>
                <rect x="57.4668" y="79.9731" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-66.7461 57.4668 79.9731)" fill="white" stroke="#848484" strokeWidth="1.20485"/>
                <path d="M89.9098 25.5299L99.7724 20.9814C99.9754 20.8878 100.215 20.9907 100.287 21.2024L103.775 31.4879C104.022 32.2176 103.679 33.0165 102.979 33.3392L93.1167 37.8877C92.9136 37.9814 92.6742 37.8785 92.6024 37.6667L89.1143 27.3813C88.8668 26.6515 89.2101 25.8527 89.9098 25.5299Z" fill="#848484"/>
                <path d="M98.9268 65.2371L100.153 65.7639L98.0184 70.7307C97.7271 71.4085 97.2833 71.8556 96.6871 72.0719C96.0941 72.2897 95.4505 72.2494 94.7565 71.9512C94.0171 71.6334 93.5264 71.2017 93.2846 70.6559C93.0427 70.1102 93.0646 69.5049 93.3503 68.84L94.5762 69.3668C94.4145 69.743 94.385 70.0665 94.4876 70.3373C94.5949 70.6062 94.8253 70.8166 95.1788 70.9685C95.5096 71.1107 95.819 71.1149 96.1069 70.9812C96.3995 70.8457 96.6287 70.585 96.7946 70.1991L98.9268 65.2371Z" fill="#848484"/>
                <rect x="77.2891" y="76.7578" width="77.2767" height="51.5178" rx="3.61456" transform="rotate(-47.3009 77.2891 76.7578)" fill="white" stroke="#848484" strokeWidth="1.20485"/>
                <ellipse cx="133.846" cy="38.255" rx="4.8298" ry="4.8298" transform="rotate(42.6991 133.846 38.255)" fill="#848484"/>
                <ellipse cx="124.838" cy="40.8957" rx="4.8298" ry="4.8298" transform="rotate(42.6991 124.838 40.8957)" fill="#848484"/>
                <ellipse cx="131.936" cy="47.446" rx="4.8298" ry="4.8298" transform="rotate(42.6991 131.936 47.446)" fill="#848484"/>
                <rect x="129.721" y="39.5601" width="4.29315" height="4.29315" transform="rotate(42.6991 129.721 39.5601)" fill="#848484"/>
                <path d="M128.387 44.1709L123.747 49.1994" stroke="#848484" strokeWidth="1.00404"/>
            </g>
            <path d="M94.1422 47.6735H93.7952V43.2858C93.7952 36.7906 88.5148 31.5103 82.0197 31.5103C75.5246 31.5103 70.2442 36.7906 70.2442 43.2858V47.6735H69.8973C67.1184 47.6735 64.8564 49.9328 64.8564 52.7143V71.5715C64.8564 74.353 67.1184 76.6123 69.8973 76.6123H94.1422C96.921 76.6123 99.183 74.353 99.183 71.5715V52.7143C99.183 49.9328 96.921 47.6735 94.1422 47.6735ZM75.8361 43.2858C75.8361 39.8762 78.6101 37.1022 82.0197 37.1022C85.4293 37.1022 88.2033 39.8762 88.2033 43.2858V47.6735H75.8361V43.2858Z" fill="#FF4A6B" stroke="white" strokeWidth="2"/>
            <path d="M86 62.5C86 64.7091 84.2091 66.5 82 66.5C79.7909 66.5 78 64.7091 78 62.5C78 60.2909 79.7909 58.5 82 58.5C84.2091 58.5 86 60.2909 86 62.5Z" fill="white"/>
            <defs>
                <filter id="filter0_d" x="0.80957" y="2.97412" width="176.746" height="122.721" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0.475 0 0 0 0 0.475 0 0 0 0 0.475 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                </filter>
            </defs>
        </svg>



    );
};

export default BlockIcon;
