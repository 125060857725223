import React, {useCallback} from "react";
import {Image, Linking, TouchableOpacity, View, Platform} from "react-native";
import BlockIcon from "../../icons/BlockIcon";
import Colors from "../../styles/colors";
import StandardText from "../../sharedComponents/standardText/StandardText";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import blurredSuits from "../../../assets/blurred_suits_bg.png";
import suitsGrid from "../../../assets/suits_grid.png";
import UserSession from "../../services/UserSession";

const SubscriptionBlocked = ({navigation, contactUsUrl}) => {

    const handleGoSubscribeButtonPress = useCallback(async (id) => {
        await UserSession.setWantsToSubscribe(id, true);
        navigation.navigate('Subscription');
    }, []);

    const handleContactUsPress = () => {
        if(Platform.OS === 'web'){
            window.open(contactUsUrl, '_blank');
        } else {
            Linking.openURL(contactUsUrl);
        }
    };

    return (
        <View style={{...ownStyles.container}}>

            <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                <Image source={blurredSuits} style={{width: '100%', height: '100%'}} resizeMode={'repeat'} />
            </View>

            <View style={{position: 'absolute', marginTop: 240, opacity: 0.5}}>
                <Image source={suitsGrid} style={{width: 320, height: 158}} resizeMode={'repeat'} />
            </View>

            <View style={{marginTop: -80}}>
                <BlockIcon/>
            </View>

            <View style={{alignItems: 'center', marginTop: 0}}>
                <StandardText style={{...ownStyles.baseText, ...ownStyles.headerText}}>Your trial period has
                    expired!</StandardText>
                <StandardText style={{...ownStyles.baseText, ...ownStyles.subheaderText}}>Please subscribe to continue{'\n'}playing.</StandardText>
                <View style={{...ownStyles.box}}>
                    <StandardText style={{...ownStyles.baseText, ...ownStyles.boxText}}>You can cancel and start{'\n'}again
                        any time!</StandardText>
                </View>

                <View style={{...ownStyles.buttonsContainer, width: 300}}>

                    <LoadingButton label={'Purchase $1.29 / month'} onPress={() => handleGoSubscribeButtonPress('3')} />
                    <LoadingButton label={'Purchase $9.99 / year'} onPress={() => handleGoSubscribeButtonPress('2')} backgroundColor={Colors.primary6} />

                    <StandardText style={{...ownStyles.baseText, fontSize: 14, marginTop: 20}}>
                        <StandardText style={{weight: 500, fontSize: 14}}>Save 35%</StandardText> with an annual subscription!
                    </StandardText>
                </View>
            </View>

            {!!contactUsUrl &&
            <View style={{backgroundColor: Colors.onBackgroundBorder3, position: 'absolute', bottom: 0, left: 0, right: 0, alignItems: 'center', padding: 20}}>
                <StandardText style={{fontSize: 14}}>Something doesn't look right?</StandardText>
                <TouchableOpacity style={{marginTop: 10}} onPress={handleContactUsPress}>
                    <StandardText style={{fontSize: 14, weight: 500}}>Contact us!</StandardText>
                </TouchableOpacity>
            </View>
            }
        </View>
    );
}

const ownStyles = {
    container: {
        backgroundColor: Colors.background,
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    baseText: {
        textAlign: 'center'
    },
    headerText: {
        fontSize: 24,
        weight: 500,
        marginBottom: 10
    },
    subheaderText: {
        fontSize: 20,
        lineHeight: 30,
        marginBottom: 10
    },
    box: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingStart: 30,
        paddingEnd: 30,
        borderWidth: 1,
        borderRadius: 4,
        borderColor: Colors.onBackgroundBorder2,
        backgroundColor: 'rgba(255, 255, 255, 0.7)'
    },
    boxText: {},
    buttonsContainer: {
        marginTop: 40
    }
};

export default SubscriptionBlocked;
