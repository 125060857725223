import { createSlice } from "@reduxjs/toolkit";
import Api from "../../services/Api";

const gameSlice = createSlice({
    name: 'game',
    initialState: {
        game: null,
        settings: {},
        lastActionId: null,
        loading: false,
        isPerformingAction: false,
        error: null
    },
    reducers: {
        requestViewerGame: {
            reducer(state, action) {
                state.loading = !action.payload.isRefresh;
                state.isPerformingAction = action.payload.isPerformingAction ? action.payload.isPerformingAction : state.isPerformingAction;
                state.error = null;
            },
            prepare(isRefresh, isPerformingAction, skipSubscriptionMiddleware) {
                return { payload: { isRefresh, isPerformingAction, skipSubscriptionMiddleware: skipSubscriptionMiddleware } }
            }
        },
        receiveViewerGame: {
            reducer(state, action) {
                state.game = action.payload.game;
                if (action.payload.actionId !== null) {
                    state.lastActionId = action.payload.actionId;
                }
                state.loading = false;
                state.isPerformingAction = action.payload.isFromAction ? false : state.isPerformingAction;
                state.error = null;
            },
            prepare(game, isFromAction, actionId) {
                return { payload: { game, isFromAction, actionId } };
            }
        },
        receiveFailedViewerGame: {
            reducer(state, action) {
                state.game = null;
                if (action.payload.actionId !== null) {
                    state.lastActionId = action.payload.actionId;
                }
                state.loading = false;
                state.isPerformingAction = action.payload.isFromAction ? false : state.isPerformingAction;
                state.error = action.payload.error;
            },
            prepare(error, actionId) {
                return { payload: { error, actionId } };
            }
        },
        requestPerformGameAction: {
            reducer(state, action) {
                state.isPerformingAction = true;
                state.error = null;
            }
        },
        receivePerformGameAction: {
            reducer(state, action) {
                state.error = null;
            }
        },
        receiveFailedPerformGameAction: {
            reducer(state, action) {
                state.isPerformingAction = false;
                state.error = null;
            }
        },
        applyPartialState: {
            reducer(state, action) {

                if (state.game.alphanumericId !== action.payload.partialState.alphanumericId) {
                    return;
                }

                if (action.payload.partialState.isRoundFinished) {
                    return;
                }

                const partialState = action.payload.partialState;
                console.log(partialState);
                state.game.isRoundFinished = partialState.isRoundFinished;
                state.game.drawPileTotalCards = partialState.drawPileTotalCards;
                state.game.discardPileTotalCards = partialState.discardPileTotalCards;
                state.game.discardPileTopCard = partialState.discardPileTopCard;

                partialState.teams.forEach((t) => {
                    const stateTeam = state.game.gameTeams.find((gt) => gt.id === t.id);
                    stateTeam.naturalCanastasLeft = t.naturalCanastasLeft;
                    stateTeam.unnaturalCanastasLeft = t.unnaturalCanastasLeft;
                    stateTeam.partialCurrentScore = t.partialCurrentScore;

                    t.players.forEach((p) => {
                        const statePlayer = stateTeam.teamPlayers.find((tp) => tp.id === p.id);
                        statePlayer.isCurrentTurn = p.isCurrentTurn;
                        statePlayer.isNextTurn = p.isNextTurn;
                        statePlayer.drawnInTurn = p.drawnInTurn;
                        statePlayer.isPlayingFoot = p.isPlayingFoot;
                        statePlayer.pickedUpPileInTurn = p.pickedUpPileInTurn;
                        statePlayer.hand = p.hand;
                    });

                    stateTeam.melds = t.melds;
                });

            },
            prepare(partialState) {
                return { payload: { partialState } };
            }
        }
    }
})

export const {
    requestViewerGame,
    receiveViewerGame,
    receiveFailedViewerGame,
    requestPerformGameAction,
    receivePerformGameAction,
    receiveFailedPerformGameAction,
    applyPartialState,
} = gameSlice.actions;

export default gameSlice.reducer;

export const fetchViewerGame = (gameAlphanumericId, isRefresh = false, isPerformingAction = false, actionId = null, skipSubscriptionMiddleware = false) => async (dispatch, getState) => {
    dispatch(requestViewerGame(isRefresh, isPerformingAction, skipSubscriptionMiddleware))

    const state = getState();
    try {
        const game = await Api.viewerGame(state.auth.login.accessToken, gameAlphanumericId);
        dispatch(receiveViewerGame(game, isPerformingAction, actionId));
    } catch (e) {
        dispatch(receiveFailedViewerGame(e.message, isPerformingAction, actionId));
    }
};

export const performGameAction = (gameAlphanumericId, action, cardIds, extraArgs, actionId) => async (dispatch, getState) => {
    dispatch(requestPerformGameAction())

    const state = getState();
    try {
        const game = await Api.performGameAction(state.auth.login.accessToken, gameAlphanumericId, action, cardIds, extraArgs);
        dispatch(receivePerformGameAction());
        if (!game.hasPartialStates) {
            dispatch(fetchViewerGame(gameAlphanumericId, true, true, actionId));
        }
    } catch (e) {
        console.error(e);
        dispatch(receiveFailedPerformGameAction(e.message));
    }
};

export const applyPartialStates = (partialStates, actionId = null) => async (dispatch, getState) => {
    let ttl = 0;
    for (let i = 0; i < partialStates.length; i++) {
        ttl += (i > 0 ? partialStates[i - 1].ttl : 0);
        setTimeout(() => {
            dispatch(applyPartialState(partialStates[i]));
        }, ttl);

        if (i === partialStates.length - 1) {
            setTimeout(() => {
                const state = getState();
                dispatch(fetchViewerGame(state.viewer.game.game.alphanumericId, true, true, actionId));
            }, ttl + partialStates[i].ttl);

        }
    }
};
