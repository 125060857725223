import {createSlice} from "@reduxjs/toolkit";
import UserSession from "../../services/UserSession";

let swap = function (array, index1, index2) {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    return array;
};

let permutationHeap = function (array, callback, n) {
    n = n || array.length;
    if (n === 1) {
        callback(array);
    } else {
        for (let i = 1; i <= n; i++) {
            permutationHeap(array, callback, n - 1);
            if (n % 2) {
                swap(array, 0, n - 1);
            } else {
                swap(array, i - 1, n - 1);
            }
        }
    }
};

const setTeams = (players, iteration) => {
    const numberOfTeams = players.length / 2;
    const combinations = [];

    permutationHeap(players, (a) => {
        const teams = [];
        for (let i = 0; i < numberOfTeams; i++) {
            teams[i] = {
                team: i + 1,
                players: []
            };
        }
        for (let j = 1; j <= a.length; j++) {
            const team = teams[Math.ceil(j / 2) - 1];
            const player = a[j - 1];
            team.players.push(player);
        }

        const combination = teams.map((t) => {
            return {team: t.team, players: t.players.slice().sort((a, b) => a.id - b.id)}
        }).slice().sort((c, d) => c.players[0].id - d.players[0].id);

        let exists = false;
        combinations.forEach((c) => {
            const j = JSON.stringify(combination.map((t) => t.players.map(p => p.id)));
            const k = JSON.stringify(c.map((t) => t.players.map(p => p.id)));

            if (j === k) {
                exists = true;
            }
        });

        if (!exists) {
            combinations.push(combination);
        }
    });

    combinations.sort((a, b) => {
        const x = a.reduce((acc, t) => acc + t.players.reduce((acc2, p) => acc2 + p.id, ''), '');
        const y = b.reduce((acc, t) => acc + t.players.reduce((acc2, p) => acc2 + p.id, ''), '');

        return parseInt(x) - parseInt(y);
    });

    return combinations;
};

const gameSetupSlice = createSlice({
    name: 'gameSetup',
    initialState: {
        currentStep: 1,
        loading: false,
        setup: {
            teamsCombinations: [],
            players: [],
            computerPlayers: [],
            cardsToDeal: null,
            totalDecks: 5,
            totalPickupCards: 6,
            red3s: 1,
            maxWildCards: 3,
            addToCompletedCanastas: 1,
            numberOfCanastas: 1,
            askToGoOut: 1,
            black3s: 2,
        },
        error: null
    },
    reducers: {
        setGameSetup: {
            reducer(state, action) {
                if (action.payload.gameSetup !== null) {
                    state.currentStep = action.payload.gameSetup.currentStep;
                    state.loading = action.payload.gameSetup.loading;
                    state.setup = action.payload.gameSetup.setup;
                    state.error = action.payload.gameSetup.error;
                } else {
                    state.currentStep = 1;
                }
            },
            prepare(gameSetup) {
                return {payload: {gameSetup}};
            }
        },
        goToNextStep: {
            reducer(state, action) {
                if (state.currentStep === 1 && [2, 3].includes(state.setup.players.length + state.setup.computerPlayers.length)) {
                    state.currentStep = state.currentStep + 2;
                } else if (state.currentStep === 4) {
                    state.currentStep = state.currentStep + 8;
                } else {
                    state.currentStep = state.currentStep + 1;
                }
                state.error = null;
            },
        },
        goToPreviousStep: {
            reducer(state, action) {
                if (state.currentStep === 3 && [2, 3].includes(state.setup.players.length + state.setup.computerPlayers.length)) {
                    state.currentStep = state.currentStep - 2;
                } else if (state.currentStep === 12) {
                    state.currentStep = 4;
                } else {
                    state.currentStep = state.currentStep > 1 ? state.currentStep - 1 : 1;
                }
                state.error = null;
            },
        },
        cancel: {
            reducer(state, action) {
                state.currentStep = 1;
                state.error = null;
                state.loading = false;
                state.setup.players = [];
                state.setup.computerPlayers = [];
                state.setup.cardsToDeal = null;
                state.setup.totalDecks = 5;
                state.setup.totalPickupCards = 6;
                state.setup.red3s = 1;
                state.setup.maxWildCards = 3;
                state.setup.addToCompletedCanastas = 1;
                state.setup.numberOfCanastas = 1;
                state.setup.askToGoOut = 2;
                state.setup.black3s = 2;
            },
        },
        setSelectedPlayers: {
            reducer(state, action) {
                state.setup.players = action.payload.players.map((p) => ({id: p.id, userAccount: p, team: null}));
                state.setup.computerPlayers = action.payload.computerPlayers.map((p) => ({
                    id: -p.id,
                    computerPlayer: p,
                    team: null
                }));
                state.setup.teamsCombinations = [];
                state.error = null;
                state.setup.cardsToDeal = action.payload.players.length + action.payload.computerPlayers.length > 3 ? 11 : 15;
                state.setup.numberOfCanastas = 1;
            },
            prepare(players, computerPlayers) {
                return {payload: {players, computerPlayers}};
            }
        },
        shufflePlayers: {
            reducer(state, action) {

                if ([2, 3].includes(state.setup.players.length + state.setup.computerPlayers.length)) {
                    state.setup.players = state.setup.players.map((player, i) => {
                        return {
                            userAccount: player.userAccount,
                            team: i + 1
                        };
                    });
                    state.setup.computerPlayers = state.setup.computerPlayers.map((player, i) => {
                        return {
                            computerPlayer: player.computerPlayer,
                            team: i + 1 + state.setup.players.length
                        };
                    });
                } else {
                    if (state.setup.teamsCombinations.length === 0) {
                        state.setup.teamsCombinations = setTeams([...state.setup.players, ...state.setup.computerPlayers], action.payload.combination)
                    }

                    console.log(action);
                    const selectedCombination = state.setup.teamsCombinations[action.payload.combination - 1];
                    state.setup.players = [];
                    state.setup.computerPlayers = [];
                    selectedCombination.forEach((team) => {
                        team.players.forEach((player) => {
                            if (player.userAccount) {
                                state.setup.players.push({
                                    ...player,
                                    team: team.team
                                })
                            } else {
                                state.setup.computerPlayers.push({
                                    ...player,
                                    team: team.team
                                })
                            }
                        });
                    });
                }
                state.error = null;
            },
            prepare(combination) {
                return {payload: {combination}};
            }
        },
        setCardsToDeal: {
            reducer(state, action) {
                state.setup.cardsToDeal = action.payload.cardsToDeal;
                state.error = null;
            },
            prepare(cardsToDeal) {
                return {payload: {cardsToDeal}};
            }
        },
        setTotalDecks: {
            reducer(state, action) {
                state.setup.totalDecks = action.payload.totalDecks;
                state.error = null;
            },
            prepare(totalDecks) {
                return {payload: {totalDecks}};
            }
        },
        setTotalPickupCards: {
            reducer(state, action) {
                state.setup.totalPickupCards = action.payload.totalPickupCards;
                state.error = null;
            },
            prepare(totalPickupCards) {
                return {payload: {totalPickupCards}};
            }
        },
        setRed3s: {
            reducer(state, action) {
                state.setup.red3s = action.payload.red3s;
                state.error = null;
            },
            prepare(red3s) {
                return {payload: {red3s}};
            }
        },
        setMaxWildCards: {
            reducer(state, action) {
                state.setup.maxWildCards = action.payload.maxWildCards;
                state.error = null;
            },
            prepare(maxWildCards) {
                return {payload: {maxWildCards}};
            }
        },
        setAddToCompletedCanastas: {
            reducer(state, action) {
                state.setup.addToCompletedCanastas = action.payload.addToCompletedCanastas;
                state.error = null;
            },
            prepare(addToCompletedCanastas) {
                return {payload: {addToCompletedCanastas}};
            }
        },
        setNumberOfCanastas: {
            reducer(state, action) {
                state.setup.numberOfCanastas = action.payload.numberOfCanastas;
                state.error = null;
            },
            prepare(numberOfCanastas) {
                return {payload: {numberOfCanastas}};
            }
        },
        setAskToGoOut: {
            reducer(state, action) {
                state.setup.askToGoOut = action.payload.askToGoOut;
                state.error = null;
            },
            prepare(askToGoOut) {
                return {payload: {askToGoOut}};
            }
        },
        setBlack3s: {
            reducer(state, action) {
                state.setup.black3s = action.payload.black3s;
                state.error = null;
            },
            prepare(black3s) {
                return {payload: {black3s}};
            }
        },
    }
})

export const {
    setGameSetup,
    cancel,
    goToNextStep,
    goToPreviousStep,
    setSelectedPlayers,
    shufflePlayers,
    setCardsToDeal,
    setTotalDecks,
    setTotalPickupCards,
    setRed3s,
    setMaxWildCards,
    setAddToCompletedCanastas,
    setNumberOfCanastas,
    setAskToGoOut,
    setBlack3s
} = gameSetupSlice.actions;

export default gameSetupSlice.reducer;

export const continueToNextStep = () => async (dispatch, getState) => {

    if (getState().viewer.gameSetup.currentStep === 1 && [2, 3].includes(getState().viewer.gameSetup.setup.players.length + getState().viewer.gameSetup.setup.computerPlayers.length)) {
        dispatch(shufflePlayers());
    }

    dispatch(goToNextStep())
    await UserSession.setNewGameSetup(getState().viewer.gameSetup);
};

export const backToPreviousStep = () => async (dispatch, getState) => {
    dispatch(goToPreviousStep())

    const state = getState();
    await UserSession.setNewGameSetup(state.viewer.gameSetup);
};

export const getGameSetupFromStorage = () => async (dispatch, getState) => {
    const gameSetup = await UserSession.getNewGameSetup();
    dispatch(setGameSetup(gameSetup))
};

export const selectSetupTotalPlayers = state => state.setup.players.length + state.setup.computerPlayers.length;