import { TouchableOpacity, View } from "react-native";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Spacing from "../../styles/spacing";
import TeamsInfo from "./TeamsInfo";
import Colors from "../../styles/colors";
import PoligonIcon from "../../customIcons/PoligonIcon";
import PlayerCards from "./PlayerCards";
import GameHelper from "../GameHelper";
import IconButton from "../../sharedComponents/iconButton/IconButton";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Pile from "./Pile";
import GameAction from "../GameAction";
import Melds from "./Melds";
import UndoIcon from "../../icons/UndoIcon";
import ScoreBoard from "./ScoreBoard";
import ScoreBoardInGame from "./ScoreBoardInGame";
import WaitingForPlayers from "./WaitingForPlayers";
import Layout from "../../styles/layout";
import CoachingMark from "../../sharedComponents/coachingMark/CoachingMark";
import { useNavigation } from "@react-navigation/native";
import Analytics, { EVENTS } from "../../services/Analytics";
import ChatIcon from "../../icons/ChatIcon";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from 'react-native-popup-menu';
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import Timer from "../../sharedComponents/timer/Timer";
import { Pressable } from "react-native";
import TurnsSummary from "./TurnsSummary";
import AskPartnerToGoOut from './AskPartnerToGoOut';
import ConfirmationDialog from '../../sharedComponents/confirmationDialog/ConfirmationDialog';
import CloseIcon from "../../icons/CloseIcon";

const GameTable = ({
    game,
    accessToken,
    roundScoreOpen = false,
    scoreBoardOpen = false,
    onGameActionTriggered,
    onGoHomePressed,
    onLeaderboardPress,
    onGameSwitched,
    windowHeight,
    disableInteractions = false,
    notificationMessage,
    showRankLabels,
    onDismissCoachingMark,
    onHideCoachingMark,
    onCoachingMarkDisplayed,
    mobileDisplay = false,
    ipadPortraitDisplay = false
}) => {

    const navigation = useNavigation();
    const [selectedCard, setSelectedCard] = useState(null);
    const [cardOrdersAsc, setCardOrdersAsc] = useState(false);
    const [showScoreBoard, setShowScoreBoard] = useState(false);
    const [tableContainerWidth, setTableContainerWidth] = useState(null);
    const [tableContainerHeight, setTableContainerHeight] = useState(null);

    const [showRequestABreak, setShowRequestABreak] = useState(false);
    const [showTurnsSummary, setShowTurnsSummary] = useState(false);
    const [selectedBreakDuration, setSelectedBreakDuration] = useState({ seconds: 300, label: '5 minutes' });
    const [loadingBreakRequest, setLoadingBreakRequest] = useState(false);
    const [currentBreak, setCurrentBreak] = useState(null);
    const [breakExpired, setBreakExpired] = useState(false);
    const [turnsSummary, setTurnsSummary] = useState([]);
    const [showStalemate, setShowStalemate] = useState(false);
    const [stalemateCanceled, setStalemateCanceled] = useState(false);
    const [showConfirmStalemateModal, setShowConfirmStalemateModal] = useState(false);

    const [showAskPartnerToGoOut, setShowAskPartnerToGoOut] = useState(false);
    const [dismissedAskPartnerToGoOut, setDismissedAskPartnerToGoOut] = useState(false);
    const [askPartnerToGoOutDismissable, setAskPartnerToGoOutDismissable] = useState(false);

    const [availableCoachingMarks, setAvailableCoachingMarks] = useState([]);
    const [currentCoachingMark, setCurrentCoachingMark] = useState(null);
    const [currentCoachingMarkIndex, setCurrentCoachingMarkIndex] = useState(0);
    const [dismissedCoachingMarks, setDismissedCoachingMarks] = useState([]);
    const [displayedCoachingMarks, setDisplayedCoachingMarks] = useState([]);


    const player = GameHelper.getViewerPlayer(game);
    const playerCardsGrouped = GameHelper.getPlayerCardsByRank(player);
    const drawPileEnabled = GameHelper.isDrawPileEnabled(game);
    const discardPileEnabled = GameHelper.isDiscardPileEnabled(game);
    const showDiscardButton = player.isCurrentTurn && (!player.isPickingUpPile && selectedCard && !GameHelper.cardIsWild(selectedCard));
    const showDiscardMessage = player.isCurrentTurn && (player.drawnInTurn || player.pickedUpPileInTurn);
    const showUndoButton = player.isCurrentTurn && (GameHelper.viewerTeamHasUncommittedCards(game) || GameHelper.viewerIsPickingUpPile(game));
    const notAllJoined = !GameHelper.allGamePlayersJoined(game) || !game.hasStarted;
    const notAllJoinedRound = !GameHelper.allGamePlayersJoinedRound(game);
    const notAllReady = !GameHelper.allGamePlayersReady(game) || !game.hasStarted;
    const numberOfPlayers = GameHelper.getAllPlayers(game).length;
    const showCoachingMarks = game.coachingMarks.filter((uacm) => uacm.show).length > 0;

    const notificationWidthThreshold = 450;
    const notificationHeightThreshold = 100;
    const notificationWidthMargin = numberOfPlayers > 2 ? 120 : 80;
    const notificationHeightMargin = 100;
    const pushDownNotificationBox = (tableContainerHeight - 96 - 634) / 2 >= notificationHeightThreshold;
    const askPartnerToGouOutEnabled = numberOfPlayers > 3 && player.isCurrentTurn && player.askPartnerToGoOutStatus === 0 && GameHelper.getViewerPartnerPlayer(game).userAccount !== null;

    const breakDurationOptions = [
        { seconds: 60, label: '1 minute' },
        { seconds: 180, label: '3 minutes' },
        { seconds: 300, label: '5 minutes' },
        { seconds: 600, label: '10 minutes' },
        { seconds: 900, label: '15 minutes' },
        { seconds: 1800, label: '30 minutes' },
        { seconds: 3600, label: '1 hour' },
    ];

    useEffect(() => {
        zE('webWidget', notAllReady || showScoreBoard || roundScoreOpen ? 'show' : 'hide');
    }, [notAllReady, showScoreBoard, roundScoreOpen]);

    useEffect(() => {
        if (player.ready === false) {
            onGameActionTriggered(GameAction.SET_READY);
        }
    }, [player]);

    useEffect(() => {
        setAvailableCoachingMarks(game.coachingMarks
            .filter((uacm) => uacm.show)
            .sort((a, b) => b.coachingMark.id - a.coachingMark.id)
            .sort((a, b) => a.coachingMark.priority - b.coachingMark.priority)
        );
        setCurrentCoachingMarkIndex(0);
    }, [game.coachingMarks]);

    useEffect(() => {
        if (availableCoachingMarks.length > 0) {
            setCurrentCoachingMark(availableCoachingMarks[currentCoachingMarkIndex]);
        }
    }, [availableCoachingMarks, currentCoachingMarkIndex]);

    useEffect(() => {
        if (currentCoachingMark) {
            if (!displayedCoachingMarks.includes(currentCoachingMark.coachingMark.id)) {
                onCoachingMarkDisplayed(currentCoachingMark.coachingMark.id);
            }
            setDisplayedCoachingMarks((current) => [...current, currentCoachingMark.coachingMark.id]);
        }
    }, [currentCoachingMark]);

    useEffect(() => {
        if (scoreBoardOpen) {
            setShowScoreBoard(true);
        }
    }, [scoreBoardOpen]);

    useEffect(() => {
        if (game.hasEnded) {
            Analytics.logEvent(EVENTS.GAME_FINISH, { alphanumeric_id: game.alphanumericId });
        }
    }, [game.hasEnded]);

    useEffect(() => {
        if (game?.breakDuration) {
            setCurrentBreak(breakDurationOptions.find(o => o.seconds === game?.breakDuration));
        } else {
            setCurrentBreak(null);
            setBreakExpired(false);
        }
    }, [game.breakStartDate]);

    useEffect(() => {
        if (!game || numberOfPlayers <= 3) return;

        if (!dismissedAskPartnerToGoOut && (
            GameHelper.getViewerPlayer(game).askPartnerToGoOutStatus > 0 ||
            GameHelper.getViewerPartnerPlayer(game).askPartnerToGoOutStatus > 0 ||
            GameHelper.getOpponentTeam(game).teamPlayers[0].askPartnerToGoOutStatus > 0 ||
            GameHelper.getOpponentTeam(game).teamPlayers[1].askPartnerToGoOutStatus > 0
        )) {
            setShowAskPartnerToGoOut(true);
        } else {
            setShowAskPartnerToGoOut(false);
        }

    }, [game, dismissedAskPartnerToGoOut]);

    useEffect(() => {
        if (!game || numberOfPlayers <= 3) return;

        if (!(
            GameHelper.getViewerPlayer(game).askPartnerToGoOutStatus > 0 ||
            GameHelper.getViewerPartnerPlayer(game).askPartnerToGoOutStatus > 0 ||
            GameHelper.getOpponentTeam(game).teamPlayers[0].askPartnerToGoOutStatus > 0 ||
            GameHelper.getOpponentTeam(game).teamPlayers[1].askPartnerToGoOutStatus > 0
        )) {
            setDismissedAskPartnerToGoOut(false);
        }

    }, [game]);

    useEffect(() => {
        if (!game || numberOfPlayers <= 3) return;

        setAskPartnerToGoOutDismissable(
            GameHelper.getViewerPlayer(game).askPartnerToGoOutStatus !== 1 &&
            GameHelper.getViewerPartnerPlayer(game).askPartnerToGoOutStatus !== 1 &&
            GameHelper.getOpponentTeam(game).teamPlayers[0].askPartnerToGoOutStatus !== 1 &&
            GameHelper.getOpponentTeam(game).teamPlayers[1].askPartnerToGoOutStatus !== 1
        );

    }, [game, numberOfPlayers]);

    useEffect(() => {
        setTurnsSummary(game?.turnsSummary);
    }, [game]);

    useEffect(() => {
        if (game?.hasAnyTeamMarkedRoundStalemate) {
            setShowStalemate(true);
        }
    }, [game]);

    useEffect(() => {
        if (showStalemate && game?.hasAnyTeamMarkedRoundStalemate === false) {
            setStalemateCanceled(true);
        }
    }, [game, showStalemate]);

    const handleOnCardSelected = useCallback((card) => {
        if (!disableInteractions && player.isCurrentTurn && (player.drawnInTurn || player.pickedUpPileInTurn || player.isPickingUpPile)) {
            setSelectedCard(selectedCard?.id === card.id ? null : card);
        }
    }, [disableInteractions, game, setSelectedCard, selectedCard]);

    const handleOnDrawPilePress = useCallback(() => {
        if (GameHelper.getViewerPlayer(game).isCurrentTurn) {
            onGameActionTriggered(GameAction.DRAW, []);
        }
    }, [game]);

    const handleOnDiscardPilePress = useCallback(() => {
        if (!player.drawnInTurn && !player.pickedUpPileInTurn) {
            onGameActionTriggered(GameAction.PICK_FROM_DISCARD_PILE);
        }
    }, [selectedCard, game]);

    const handleOnMeldPress = useCallback((cardRank, meld) => {
        if (!player.isCurrentTurn || (!player.drawnInTurn && !player.pickedUpPileInTurn && !player.isPickingUpPile)) {
            return;
        }

        if (selectedCard) {
            if (selectedCard?.rank === cardRank || GameHelper.cardIsWild(selectedCard)) {
                onGameActionTriggered(GameAction.MELD, [selectedCard.id], [cardRank]);
                setSelectedCard(null);
            }
        } else {
            onGameActionTriggered(GameAction.MELD, [], [cardRank]);
        }
    }, [selectedCard, game]);

    const handleOnDiscardButtonPress = useCallback(() => {
        if (selectedCard) {
            onGameActionTriggered(GameAction.DISCARD, [selectedCard.id]);
            setSelectedCard(null);
        }
    }, [selectedCard, game]);

    const handleUndoPress = useCallback(() => {
        onGameActionTriggered(GameAction.UNMELD_ALL, [], []);
        setSelectedCard(null);
    }, [game]);

    const handleOnScoreButtonPressed = useCallback(() => {
        setShowScoreBoard(true);
    }, []);

    const handleOnCloseBoardPressed = useCallback(() => {
        setShowScoreBoard(false);
    }, []);

    const handleOnRulesPress = useCallback(() => {
        Analytics.logEvent(EVENTS.BUTTON_PRESS, { name: 'rules' });
        setShowScoreBoard(true);
    }, []);

    const handleOnTutorialPress = useCallback(() => {
        Analytics.logEvent(EVENTS.BUTTON_PRESS, { name: 'tutorial' });
        navigation.navigate('Tutorial', { reset: 1 })
    }, []);

    const handleOnDismissCoachingMarkPress = useCallback((id) => {
        setDismissedCoachingMarks((current) => {
            return [...current, id];
        });

        setCurrentCoachingMarkIndex((v) => v + 1);

        if (onDismissCoachingMark) {
            onDismissCoachingMark(id);
        }
    }, [onDismissCoachingMark]);

    const handleRequestABreakPress = useCallback(() => {
        setSelectedBreakDuration({ seconds: 300, label: '5 minutes' })
        setShowRequestABreak(true);
    }, []);

    const handleWhatDidIMissPress = useCallback(() => {
        setShowTurnsSummary(true);
    }, []);

    const handleAskPartnerToGoOutPress = useCallback(() => {
        onGameActionTriggered(GameAction.ASK_PARTNER_TO_GO_OUT, [], []);
    }, []);

    const handleOnKeepPlayingPress = useCallback(() => {
        onGameActionTriggered(GameAction.ANSWER_PARTNER_TO_GO_OUT, [], [0]);
    }, []);

    const handleOnAcceptGoOutPress = useCallback(() => {
        onGameActionTriggered(GameAction.ANSWER_PARTNER_TO_GO_OUT, [], [1]);
    }, []);

    const handleOnAskPartnerToGoOutOverlayPress = useCallback(() => {
        if (askPartnerToGoOutDismissable) {
            setDismissedAskPartnerToGoOut(true);
        }
    }, [askPartnerToGoOutDismissable]);

    const handleSubmitBreakRequestPress = useCallback(async () => {
        setLoadingBreakRequest(true);
        onGameActionTriggered(GameAction.START_BREAK, [], [selectedBreakDuration.seconds]);
        setLoadingBreakRequest(false);
    }, [selectedBreakDuration]);

    const handleIMBackPress = useCallback(async () => {
        onGameActionTriggered(GameAction.FINISH_BREAK, [], []);
        setLoadingBreakRequest(false);
        setShowRequestABreak(false);
    }, []);

    const handleOnBreakOverlayPress = useCallback(() => {
        if (showRequestABreak && !game.breakStartDate) {
            setShowRequestABreak(false);
        }
    }, [showRequestABreak, game]);

    const handleTurnsSummaryOverlayPress = useCallback(() => {
        setShowTurnsSummary(false);
    }, [showRequestABreak, game]);

    const handleMarkAStalematePress = useCallback(() => {
        setShowConfirmStalemateModal(true);
    }, []);

    const handleConfirmStalematePress = useCallback(() => {
        setShowConfirmStalemateModal(false);
        onGameActionTriggered(GameAction.MARK_ROUND_STALEMATE, [], []);
    }, []);

    const handleCancelStalematePress = useCallback(() => {
        setShowConfirmStalemateModal(false);
        setShowStalemate(false);
        onGameActionTriggered(GameAction.CANCEL_MARK_ROUND_STALEMATE, [], []);
        setStalemateCanceled(false);
    }, []);

    return (
        <View style={[ownStyles.screenContainer]}>

            {roundScoreOpen && (
                <View style={[ownStyles.blurContainer]}>
                    <ScoreBoard game={game} onStartRoundPress={() => {
                        onGameActionTriggered(GameAction.JOIN_ROUND)
                    }} onGoHomePress={onGoHomePressed} onLeaderboardPress={onLeaderboardPress} />
                </View>
            )}

            {(showScoreBoard && !roundScoreOpen) && (
                <View style={{ ...ownStyles.blurContainer, zIndex: 11 }}>
                    <ScoreBoardInGame game={game}
                        onClosePress={handleOnCloseBoardPressed} onGoHomePress={onGoHomePressed}
                        onGameSwitched={onGameSwitched} showRulesDirectly={notAllJoined} />
                </View>
            )}

            {notAllReady &&
                <View style={{ ...Layout.fullOverlay }}>
                    <WaitingForPlayers
                        game={game}
                        onRulesPress={handleOnRulesPress}
                        onTutorialPress={handleOnTutorialPress}
                        onCancelPress={onGoHomePressed} />
                </View>
            }

            {!showScoreBoard &&
                <View style={[ownStyles.mainContainer]}>

                    <ConfirmationDialog title={'Confirmation'}
                        content={'Are you sure you want to mark this round a stalemate?'}
                        onCancelPress={() => {
                            setShowConfirmStalemateModal(false)
                        }}
                        onConfirmPress={handleConfirmStalematePress}
                        visible={showConfirmStalemateModal} />

                    {!!notificationMessage && (
                        <View style={{
                            position: 'absolute',
                            top: pushDownNotificationBox ? notificationHeightMargin : 0,
                            left: tableContainerWidth < notificationWidthThreshold || pushDownNotificationBox ? (mobileDisplay ? 150 : 360) : (mobileDisplay ? 150 : 360) + notificationWidthMargin,
                            right: tableContainerWidth < notificationWidthThreshold || pushDownNotificationBox ? 0 : notificationWidthMargin,
                            margin: 20,
                            zIndex: 10
                        }}>
                            {notificationMessage}
                        </View>
                    )}

                    {showCoachingMarks && currentCoachingMark && !dismissedCoachingMarks.includes(currentCoachingMark.coachingMark.id) &&
                        <CoachingMark
                            key={currentCoachingMark.coachingMark.id}
                            coachingMark={currentCoachingMark.coachingMark}
                            useMobilePosition={mobileDisplay}
                            onDismissPress={() => {
                                handleOnDismissCoachingMarkPress(currentCoachingMark.coachingMark.id)
                            }} onHidePress={() => {
                                onHideCoachingMark(currentCoachingMark.coachingMark.id);
                                handleOnDismissCoachingMarkPress(currentCoachingMark.coachingMark.id);
                            }}
                        />
                    }

                    {(showRequestABreak || game.breakStartDate) &&
                        <View style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>

                            <Pressable style={{ ...ownStyles.bottomModalOverlay }} onPress={handleOnBreakOverlayPress}></Pressable>

                            <View style={{ position: 'absolute', top: mobileDisplay ? 10 : 15, left: mobileDisplay ? 10 : 15, flexDirection: 'row', zIndex: 10 }}>
                                <IconButton icon={<PoligonIcon size={24} color={Colors.onBackground} />}
                                    onPress={handleOnScoreButtonPressed} />
                            </View>

                            <View style={{ ...ownStyles.bottomModal }}>
                                {!currentBreak ?
                                    <View style={{ height: '100%', maxWidth: 300, justifyContent: 'space-between', alignItems: 'center' }}>
                                        <StandardText style={{ ...ownStyles.modalMainText }}>
                                            How long a break do you think you will need?
                                        </StandardText>

                                        <View style={{ width: 230, alignItems: 'stretch' }}>
                                            <Menu>
                                                <MenuTrigger customStyles={{
                                                    TriggerTouchableComponent: LoadingButton,
                                                    triggerTouchable: {
                                                        label: selectedBreakDuration?.label,
                                                        showChevron: true,
                                                        chevronDirection: 1,
                                                        disabled: loadingBreakRequest
                                                    }
                                                }} />
                                                <MenuOptions customStyles={{
                                                    ...ownStyles.menuOptions,
                                                    optionsContainer: { backgroundColor: 'transparent', marginTop: -50, boxShadow: 'none' }
                                                }}>
                                                    {
                                                        breakDurationOptions.map((option) =>
                                                            <MenuOption key={option.seconds} customStyles={ownStyles.menuOption} onSelect={() => {
                                                                setSelectedBreakDuration(option)
                                                            }} text={option.label} />
                                                        )
                                                    }
                                                </MenuOptions>
                                            </Menu>

                                            <LoadingButton label={'Submit'} showChevron={true} backgroundColor={Colors.primary6}
                                                onPress={handleSubmitBreakRequestPress} loading={loadingBreakRequest} />
                                        </View>
                                    </View> :
                                    <View style={{ height: '100%', maxWidth: 300, justifyContent: 'space-between' }}>

                                        {game.breakRequesterTeamPlayer.id === player?.id ?
                                            (breakExpired ?
                                                <StandardText style={{ ...ownStyles.modalMainText }}>
                                                    You have asked for a break of {currentBreak.label} but <StandardText style={{ ...ownStyles.modalMainText, color: Colors.redCard }}>exceeded the time you requested</StandardText>. The other players have been informed.
                                                </StandardText>
                                                :
                                                <StandardText style={{ ...ownStyles.modalMainText }}>
                                                    You have asked for a break of {currentBreak.label}. The other players have been informed.
                                                </StandardText>)
                                            :
                                            (breakExpired ?
                                                <StandardText style={{ ...ownStyles.modalMainText }}>
                                                    {game.breakRequesterTeamPlayer.firstName} <StandardText style={{ ...ownStyles.modalMainText, color: Colors.redCard }}>exceeded the time they requested</StandardText>.
                                                </StandardText>
                                                :
                                                <StandardText style={{ ...ownStyles.modalMainText }}>
                                                    {game.breakRequesterTeamPlayer.firstName} has asked for a break of {currentBreak.label}.
                                                </StandardText>)
                                        }

                                        <StandardText style={{ ...ownStyles.modalMainText }}>
                                            <Timer duration={Math.floor((GameHelper.getBreakEndDate(game).getTime() - Date.now()) / 1000)} onExpire={() => { setBreakExpired(true) }} />
                                        </StandardText>

                                        {game.breakRequesterTeamPlayer.id === player.id ?
                                            <LoadingButton label={'I\'m back'} showChevron={false} backgroundColor={Colors.primary6}
                                                onPress={handleIMBackPress} /> :
                                            <StandardText style={{ ...ownStyles.modalMainText }}>
                                                {'(This window will automatically\ndismiss when ' + game.breakRequesterTeamPlayer.firstName + ' is back)'}
                                            </StandardText>
                                        }

                                    </View>}
                            </View>
                        </View>
                    }

                    {(showTurnsSummary) &&
                        <View style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>

                            <Pressable style={{ ...ownStyles.bottomModalOverlay }} onPress={handleTurnsSummaryOverlayPress}></Pressable>

                            <View style={{ ...ownStyles.bottomModal, padding: 0, paddingTop: 0, height: null, height: 520 }}>
                                <TurnsSummary turnsSummary={turnsSummary} viewerPlayerId={GameHelper.getViewerPlayer(game).id} mobileDisplay={mobileDisplay} />
                            </View>
                        </View>
                    }

                    {(game && showAskPartnerToGoOut) &&
                        <View style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>

                            <Pressable style={{ ...ownStyles.bottomModalOverlay }}></Pressable>

                            <View style={{ position: 'absolute', top: mobileDisplay ? 10 : 15, left: mobileDisplay ? 10 : 15, flexDirection: 'row', zIndex: 10 }}>
                                <IconButton icon={<PoligonIcon size={24} color={Colors.onBackground} />}
                                    onPress={handleOnScoreButtonPressed} />
                            </View>

                            <View style={{ ...ownStyles.bottomModal, padding: 0, paddingTop: 0, height: null, height: 260 }}>
                                <AskPartnerToGoOut
                                    viewer={GameHelper.getViewerPlayer(game)}
                                    partner={GameHelper.getViewerPartnerPlayer(game)}
                                    opponent1={GameHelper.getOpponentTeam(game).teamPlayers[0]}
                                    opponent2={GameHelper.getOpponentTeam(game).teamPlayers[1]}
                                    onKeepPlayingPress={handleOnKeepPlayingPress}
                                    onAcceptPress={handleOnAcceptGoOutPress}
                                    dismissable={askPartnerToGoOutDismissable}
                                    onDismissPress={handleOnAskPartnerToGoOutOverlayPress}
                                />
                            </View>
                        </View>
                    }

                    {showStalemate &&
                        <View style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10 }}>

                            <Pressable style={{ ...ownStyles.bottomModalOverlay }}></Pressable>

                            <View style={{ position: 'absolute', top: mobileDisplay ? 10 : 15, left: mobileDisplay ? 10 : 15, flexDirection: 'row', zIndex: 10 }}>
                                <IconButton icon={<PoligonIcon size={24} color={Colors.onBackground} />}
                                    onPress={handleOnScoreButtonPressed} />
                            </View>

                            <View style={{ ...ownStyles.bottomModal }}>
                                {stalemateCanceled &&
                                    <View style={{ position: 'absolute', right: 10, top: 10 }}>
                                        <TouchableOpacity onPress={() => { setShowStalemate(false);setStalemateCanceled(false); }}>
                                            <CloseIcon size={26} />
                                        </TouchableOpacity>
                                    </View>
                                }
                                <View style={{ height: '100%', maxWidth: 300, justifyContent: 'space-between', alignItems: 'center' }}>
                                    {!stalemateCanceled &&
                                        (!GameHelper.getViewerTeam(game).hasAnyPlayerMarkedStalemate ?
                                            <View>
                                                <StandardText style={{ ...ownStyles.modalMainText }}>
                                                    The other team has marked the round a stalemate. Do you confirm?
                                                </StandardText>
                                                <LoadingButton label={'No'} onPress={handleCancelStalematePress} showChevron={false} />
                                                <LoadingButton label={'Yes'} onPress={handleConfirmStalematePress} backgroundColor={Colors.primary6} />
                                            </View>
                                            :
                                            <StandardText style={{ ...ownStyles.modalMainText }}>
                                                Waiting for other team(s) to confirm round as stalemate...
                                            </StandardText>
                                        )
                                    }
                                    {stalemateCanceled &&
                                        <View>
                                            <StandardText style={{ ...ownStyles.modalMainText }}>
                                                The other team(s) did not confirm this round was a stalemate.
                                            </StandardText>
                                        </View>
                                    }
                                </View>

                            </View>
                        </View>
                    }

                    <View style={{ ...ownStyles.drawerContainer, width: mobileDisplay ? 150 : 360 }}>

                        <View style={{
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            padding: mobileDisplay ? 10 : Spacing.large,
                            flexWrap: 'wrap'
                        }}>
                            <View style={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: mobileDisplay ? 0 : 20
                            }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <IconButton icon={<PoligonIcon size={24} color={Colors.onBackground} />}
                                        onPress={handleOnScoreButtonPressed} />
                                    {!mobileDisplay &&
                                        <View style={{ width: 50, marginLeft: 5 }}>
                                            <StandardText>Round {GameHelper.getRoundPoints(game.currentRound)}</StandardText>
                                        </View>
                                    }
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <Menu>
                                        <MenuTrigger>
                                            <IconButton icon={<ChatIcon size={24} color={Colors.onBackground} />} />
                                        </MenuTrigger>
                                        <MenuOptions customStyles={ownStyles.menuOptions}>
                                            <MenuOption customStyles={{ ...ownStyles.menuOption, ...(!askPartnerToGouOutEnabled ? ownStyles.menuOptionDisabled : {}) }} disabled={!askPartnerToGouOutEnabled} onSelect={handleAskPartnerToGoOutPress} text='Ask Partner To Go Out' />
                                            <MenuOption customStyles={ownStyles.menuOption} onSelect={handleRequestABreakPress} text='Request A Break' />
                                            <MenuOption customStyles={ownStyles.menuOption} onSelect={handleWhatDidIMissPress} text='What Did I Miss?' />
                                            <MenuOption customStyles={ownStyles.menuOption} onSelect={handleMarkAStalematePress} text='Mark Round A Stalemate' />
                                        </MenuOptions>
                                    </Menu>
                                    {showUndoButton &&
                                        <View style={{ marginLeft: 2 }}>
                                            <IconButton icon={<UndoIcon size={24} color={Colors.onBackground} />}
                                                onPress={handleUndoPress} />
                                        </View>
                                    }
                                </View>
                            </View>

                        </View>

                        <View style={{
                            padding: 0,
                            alignItems: mobileDisplay ? 'start' : 'center',
                            paddingLeft: mobileDisplay ? 10 : 0,
                            flex: 1,
                            justifyContent: 'space-between'
                        }}>
                            <View style={{ padding: 0, alignItems: mobileDisplay ? 'start' : 'center', flex: 1 }}>
                                <View style={{
                                    marginBottom: 0,
                                    width: mobileDisplay ? 120 : 160,
                                    textAlign: mobileDisplay ? 'left' : 'center',
                                    height: 40,
                                    justifyContent: 'center'
                                }}>
                                    {showDiscardMessage ?
                                        <StandardText style={{ fontSize: 14, color: Colors.onBackground }}>
                                            <StandardText style={{ fontSize: 14, weight: 500 }}>Discard to end
                                                turn</StandardText> or keep melding.
                                        </StandardText>
                                        :
                                        (mobileDisplay && <StandardText style={{ fontSize: 16, color: Colors.onBackground }}>
                                            Round{'\n'}{GameHelper.getRoundPoints(game.currentRound)}
                                        </StandardText>)
                                    }
                                </View>

                                <View style={{
                                    ...ownStyles.playerCardsContainer,
                                    flexShrink: mobileDisplay ? 1 : 0,
                                    minHeight: mobileDisplay ? 380 : null,
                                    maxHeight: mobileDisplay ? windowHeight - 230 : null,
                                    width: mobileDisplay ? 130 : 280,
                                    marginLeft: mobileDisplay ? -5 : 0, ...(mobileDisplay ? { flexGrow: 1 } : {})
                                }}>
                                    <PlayerCards cardsGrouped={playerCardsGrouped} selectedCard={selectedCard}
                                        onCardSelected={handleOnCardSelected} asc={cardOrdersAsc}
                                        compact={mobileDisplay}
                                        disableInteractions={disableInteractions || !player.isCurrentTurn} />
                                </View>

                                <View style={{
                                    flex: 1,
                                    minHeight: 'fit-content',
                                    alignItems: 'center',
                                    width: mobileDisplay ? 130 : 270,
                                    paddingBottom: mobileDisplay ? 10 : 0,
                                    marginBottom: mobileDisplay ? 0 : 10, ...(mobileDisplay ? { justifyContent: 'flex-end' } : {})
                                }}>
                                    <View style={{ flexDirection: 'column', width: '100%' }}>
                                        <View style={{ marginTop: 10, width: '100%' }}>
                                            <Pile type={'draw'} onPress={handleOnDrawPilePress}
                                                totalCards={game.drawPileTotalCards}
                                                disabled={disableInteractions || !drawPileEnabled} />
                                        </View>
                                        <View style={{ marginTop: 20 }}>
                                            <Pile type={'discard'} topCard={game.discardPileTopCard}
                                                onPress={handleOnDiscardPilePress} totalCards={game.discardPileTotalCards}
                                                disabled={disableInteractions || !discardPileEnabled} />
                                        </View>
                                    </View>
                                </View>
                            </View>

                            {showDiscardButton &&
                                <View style={{ ...ownStyles.discardButtonContainer, ...(mobileDisplay ? { height: 130 } : {}) }}>
                                    <TouchableOpacity style={ownStyles.discardButton} onPress={handleOnDiscardButtonPress}>
                                        <View style={{
                                            flexDirection: mobileDisplay ? 'column' : 'row',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            justifyContent: mobileDisplay ? 'center' : 'start'
                                        }}>
                                            <View style={{
                                                ...ownStyles.discardIcon,
                                                marginRight: mobileDisplay ? 0 : 10,
                                                marginBottom: mobileDisplay ? 10 : 0
                                            }} />
                                            <View>
                                                <View style={{ width: '100%' }}>
                                                    <StandardText style={{ color: Colors.primary, textAlign: 'center' }}>
                                                        <StandardText style={{ fontSize: 16, color: Colors.primary }}
                                                            weight={500}>
                                                            Tap to
                                                            discard</StandardText>{mobileDisplay ? `\n` : ` `}and <StandardText
                                                                style={{ fontSize: 16, color: Colors.primary }} weight={500}>end
                                                            turn</StandardText>
                                                    </StandardText></View>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            }
                        </View>

                    </View>

                    <View style={[ownStyles.tableContainer]} onLayout={(event) => {
                        const { x, y, width, height } = event.nativeEvent.layout;
                        setTableContainerWidth(width);
                        setTableContainerHeight(height);
                    }}>


                        <View style={[ownStyles.teamsInfoContainer]}>
                            <TeamsInfo game={game} />
                        </View>

                        <View style={{ flexGrow: 1 }}>
                            <Melds game={game} onMeldPressed={handleOnMeldPress}
                                viewerTeamMelds={GameHelper.getViewerTeam(game).melds}
                                opponentTeamMelds={GameHelper.getOpponentTeam(game).melds}
                                selectedCard={selectedCard}
                                showRankLabels={showRankLabels}
                                mobileDisplay={mobileDisplay}
                                ipadPortraitDisplay={ipadPortraitDisplay}
                                disabled={disableInteractions || !player.isCurrentTurn} />
                        </View>

                    </View>

                </View>
            }

        </View>

    );
};

const ownStyles = {
    screenContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
    },
    mainContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        position: 'relative'
    },
    bottomContainer: {
        height: 69,
        backgroundColor: Colors.background,
    },
    drawerContainer: {
        width: 360,
        padding: 0,
        flexDirection: 'column',
        zIndex: 1,
        boxShadow: '0px 4px 8px rgba(128, 128, 128, 0.4)',
        alignItems: 'stretch'
    },
    playerCardsContainer: {
        width: 280
    },
    tableContainer: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: Colors.backgroundSecondary,
    },
    teamsInfoContainer: {
        backgroundColor: Colors.background,
        height: 96
    },
    discardButtonContainer: {
        backgroundColor: Colors.background,
        height: 200,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        borderTopWidth: 2,
        borderColor: Colors.primary
    },
    discardButton: {
        backgroundColor: Colors.primary3,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    discardIcon: {
        width: 48,
        height: 36,
        backgroundColor: Colors.background,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: Colors.primary,
        marginRight: 10
    },
    blurContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: Colors.background
    },
    transparentOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        backgroundColor: 'rgba(233, 233, 233, 0.9)',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    joinedCheckContainer: {
        width: 17,
        height: 17,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: -2,
        right: -2,
        padding: 2,
        borderRadius: 20,
        borderWidth: 2,
        borderColor: Colors.success2,
        backgroundColor: Colors.success2
    },
    rulesButton: {
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgb(190,190,190)',
        padding: 8,
        alignItems: 'center',
        backgroundColor: Colors.background
    },
    menuOptions: {
        optionsContainer: {
            backgroundColor: 'transparent',
            marginTop: 45,
            boxShadow: 'none'
        }
    },
    menuOption: {
        optionWrapper: {
            backgroundColor: Colors.background,
            marginBottom: -2,
            borderRadius: 3,
            borderWidth: 2,
            padding: 10,
            borderColor: Colors.onBackground,
            boxShadow: 'rgb(0 0 0 / 20%) 3px 3px 4px'
        },
        optionText: {
            color: Colors.onBackground,
        }
    },
    menuOptionDisabled: {
        optionText: {
            color: Colors.onBackgroundBorder
        }
    },
    bottomModalOverlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(17, 32, 74, 0.4)',
        zIndex: 10
    },
    bottomModal: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        flex: 1,
        width: '100%',
        height: 300,
        backgroundColor: Colors.background,
        padding: Spacing.large,
        paddingTop: 40,
        alignItems: 'center',
        zIndex: 10,
        justifyContent: 'space-between'
    },
    modalMainText: {
        weight: 500,
        color: Colors.onBackground,
        fontSize: 20,
        lineHeight: 32,
        textAlign: 'center'
    }
};

export default GameTable;
