import React, {useCallback, useContext, useState} from 'react';
import {useNavigation} from "@react-navigation/native";
import {SafeAreaView, ScrollView, TouchableOpacity, View, Linking, ActivityIndicator} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import GameHelper from "../GameHelper";
import Spacing from "../../styles/spacing";
import Colors from "../../styles/colors";
import IconButton from "../../sharedComponents/iconButton/IconButton";
import ChevronIcon from "../../icons/ChevronIcon";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import Api from "../../services/Api";
import WebView from "react-native-web-webview/src";

const GameConfigSummary = ({accessToken, game, onlyGeneralRules = false, onBackButtonPress}) => {

    const loadingContext = useContext(LoadingContext);
    const [showGeneralRules, setShowGeneralRules] = useState(false);
    const [loadingGeneralRules, setLoadingGeneralRules] = useState(true);
    const [generalAppInfo, setGeneralAppInfo] = useState(null);

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getInfo(accessToken);
        }
    }, [accessToken]));

    const totalPlayers = game ? GameHelper.getAllPlayers(game).length : 0;

    const getInfo = async (accessToken) => {
        loadingContext.setScreenLoading(false);
        setLoadingGeneralRules(true);
        const generalAppInfoData = await Api.generalAppInfo(accessToken);
        setGeneralAppInfo(generalAppInfoData);

        setTimeout(() => {
            setLoadingGeneralRules(false);
        }, 500);
    };

    const handleTeachMeBasicsPress = () => {
        window.open(
            'https://hffe.zendesk.com/hc/en-us/articles/360056282931',
            '_blank'
        );
    };

    const handleShowGeneralRulesPress = () => {
        setShowGeneralRules(false);
        if (onlyGeneralRules) {
            onBackButtonPress();
        }
    };

    return (
        <View style={{height: '100%'}}>

            {(onlyGeneralRules || showGeneralRules) && (
                <View style={{...ownStyles.generalRulesContainer}}>
                    <View style={{...ownStyles.header}}>
                        <View style={{
                            position: 'absolute',
                            top: 15,
                            left: 15,
                            zIndex: 1,
                            transform: [{rotate: '180deg'}]
                        }}>
                            <IconButton icon={<ChevronIcon size={20} color={Colors.onBackground}/>}
                                        onPress={handleShowGeneralRulesPress}/>
                        </View>
                        <View>
                            <StandardText style={{fontSize: 20, weight: 500, color: Colors.onBackground4}}>
                                General Rules
                            </StandardText>
                        </View>
                    </View>

                    <View style={{flex: 1, paddingTop: 72}}>

                        {loadingGeneralRules && (
                            <View style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.background}}>
                                <ActivityIndicator color={Colors.onBackground} animating={true}/>
                            </View>
                        )}

                        {generalAppInfo && (
                            <WebView
                                source={{html: '<html><head><link rel="preconnect" href="https://fonts.gstatic.com"><link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap" rel="stylesheet"><style>body {font-family: \'Roboto\', sans-serif}</style></head>' + generalAppInfo.generalRules + '</body></html>'}}/>
                        )}
                    </View>
                </View>
            )}

            {!onlyGeneralRules && !showGeneralRules && (
                <View style={{height: '100%'}}>
                    <View style={{...ownStyles.header}}>
                        <View style={{
                            position: 'absolute',
                            top: 15,
                            left: 15,
                            zIndex: 1,
                            transform: [{rotate: '180deg'}]
                        }}>
                            <IconButton icon={<ChevronIcon size={20} color={Colors.onBackground}/>}
                                        onPress={onBackButtonPress}/>
                        </View>
                        <View>
                            <StandardText style={{fontSize: 20, weight: 500, color: Colors.onBackground4}}>
                                Game Configuration
                            </StandardText>
                            <StandardText style={{fontSize: 14, textAlign: 'center', color: Colors.onBackground3}}>Game ID: {game.id}</StandardText>
                        </View>
                    </View>

                    <ScrollView style={{height: '100%', paddingTop: 72, paddingBottom: 57}}>
                        {totalPlayers > 0 && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}># of players</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}></StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{totalPlayers}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.cardsToDeal !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Cards dealt</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select how many cards
                                        you
                                        want dealt in each player’s hand and in their foot. We recommend 11 cards in a 4
                                        player
                                        game and 15 cards in a 2 player game.</StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.cardsToDeal}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.totalDecks !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Decks used</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>We recommend 5 decks
                                        of
                                        cards
                                        for games with 2 or 4 players.</StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.totalDecks}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.totalPickupCards !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>By picking up a card you'll
                                        get</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select how many cards
                                        you
                                        get
                                        when you pick up cards from the “Pick Up” pile. We recommend 6.</StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.totalPickupCards}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.red3s !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Red 3s</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>"Play immediately"
                                        will
                                        automatically play your red 3, give you 100 points and let you draw another
                                        card.
                                        "Use
                                        as discard" lets you use any red 3s in your hand as a valid
                                        discard.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.red3s === 1 ? `Use as discard` : `Play immediately`}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.maxWildCards !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Max number of wild cards in
                                        melds</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select the maximum
                                        number
                                        of
                                        wild cards you can use in an unnatural meld. We recommend 3.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.maxWildCards}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.addToCompletedCanastas !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Cards can be added to\ncompleted canastas`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>"Any time" lets you
                                        add
                                        additional cards to a canasta at any time during a round, while "Only at end"
                                        only
                                        lets
                                        you play additional cards when going out.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.addToCompletedCanastas === 1 ? `Any time` : `Only at end`}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.naturalCanastasNeeded !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Number of canastas to go out`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>We recommend 3 Red, 4
                                        Black
                                        for a 2 player game and 4 Red, 5 Black for a 4 player game. Red means a canasta
                                        with
                                        no
                                        wild cards and Black means a canasta that uses wild cards.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{`${game.gameConfig.naturalCanastasNeeded} Red, ${game.gameConfig.unnaturalCanastasNeeded} Black`}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.askToGoOut !== null && [4, 6].includes(totalPlayers) && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Should players ask partner to go out?`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select "Yes" to
                                        require a
                                        player to ask their partner for permission before they go out.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.askToGoOut === 1 ? `Yes` : `No`}</StandardText>
                            </View>
                        )}
                        {game.gameConfig.black3s !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Can Black 3s be melded when going out?`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select "Yes" to allow
                                        a
                                        player to meld three or more Black 3s from their hand when they are trying to go
                                        out.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{game.gameConfig.black3s === 1 ? `Yes` : `No`}</StandardText>
                            </View>
                        )}

                    </ScrollView>

                    <View style={{...ownStyles.buttonsContainer}}>                        
                        <View style={{minWidth: 240, marginStart: Spacing.small, marginEnd: Spacing.small}}>
                            <LoadingButton label={'General rules'} showChevron={true} backgroundColor={Colors.primary4}
                                           onPress={() => setShowGeneralRules(true)}/>
                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};

const ownStyles = {
    generalRulesContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        zIndex: 3
    },
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        height: 72,
        backgroundColor: Colors.background,
        zIndex: 1
    },
    gameSetupRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: Spacing.large,
        paddingStart: Spacing.base,
        borderBottomWidth: 1,
        borderBottomColor: Colors.onBackgroundBorder2
    },
    gameSetupLabelContainer: {
        flex: 1
    },
    gameSetupLabel: {
        color: Colors.onBackground3
    },
    gameSetupLabelDescription: {
        fontSize: 14,
        color: Colors.onBackground3,
        opacity: 0.7,
        marginTop: Spacing.small,
    },
    gameSetupValue: {
        color: Colors.primary4,
        paddingStart: Spacing.base,
        textAlign: 'right'
    },
    buttonsContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        alignItems: 'center',
        backgroundColor: Colors.background
    }
};

export default GameConfigSummary;
